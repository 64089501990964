import { render, staticRenderFns } from "./CustomerBranchForm.vue?vue&type=template&id=54ce8ea4&scoped=true&"
import script from "./CustomerBranchForm.vue?vue&type=script&lang=js&"
export * from "./CustomerBranchForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ce8ea4",
  null
  
)

export default component.exports