<template>
  <div :key="'screen'+indexScreen" :id="'screen'+indexScreen">
    <div class="row pl-5 pr-5 pb-0 pt-0">
      <div class="col-md-6 pl-0 pr-0 border-1-EBEDF3-without-top">

        <div class="d-flex justify-content-between col-md-12 pl-3 pr-3 pt-4 pb-4">
          <div class="d-flex my-auto">
            <span @click="getClient"><i class="fa fa-user-circle pl-0" style="font-size: 26px;"></i></span>
            <span @click="getClient" class="ml-3 mr-3 pt-1 cursor-pointer">{{ client_name }}</span>
          </div>
          <div class="d-flex">
            <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('discount')" :disabled="selected_items.length < 1" @click="addAction('dis_per')">
              <i class="flaticon2-percentage text-0C6F58 pl-0"></i>
            </button>
            <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('shipping')" :disabled="selected_items.length < 1" @click="addAction('shipping')">
              <i class="flaticon2-delivery-truck text-0C6F58 pl-0"></i>
            </button>
            <button class="btn btn-light btn-sm ml-2 mr-2" v-if="$can('point_sales.update_pos_price')" v-b-tooltip.hover :title="$t('price')" :disabled="selected_items.length < 1" @click="addAction('price')">
              <i class="flaticon-coins text-0C6F58 pl-0"></i>
            </button>
            <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('pricing_information')" @click="showNotesCartModal">
              <i class="flaticon-notepad text-0C6F58 pl-0"></i>
            </button>
            <button class="btn btn-light btn-sm ml-2 mr-2" v-b-tooltip.hover :title="$t('remove_all')" @click="removeAllSelectedItem">
              <i class="flaticon-delete text-danger pl-0"></i>
            </button>
          </div>
        </div>

        <div class="form-group mb-4 mr-3 ml-3">
          <div class="input-group input-group-solid">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-barcode"></i>
              </span>
            </div>
            <input type="text" class="form-control" :id="'barcodeInput'+indexScreen" :key="'barcodeInput'+indexScreen" :ref="'barcodeInput'+indexScreen" placeholder="ادخل الباركود" v-model="data.barcode" autocomplete="off">
            <div class="input-group-append">
              <button @click="searchOnEnterInput" class="btn btn-primary"><i class="fa fa-search p-0"></i></button>
            </div>

          </div>
        </div>


        <div class="col-md-12 pl-0 pr-0" :key="'screen1'+indexScreen" :id="'screenCart'+indexScreen">
          <div style="height: 487px;" :key="'screen2'+indexScreen">
            <div style="overflow-y: scroll;height: 310px;" :key="'screen3'+indexScreen">
              <div class="d-flex border-bottom-1-EBEDF3" v-for="(row, index) in selected_items" :key="'screen'+indexScreen+index"  :style="{'background-color': (index==index_item_selected? '#fff6d4': (row.is_return? '#f2f2f2':'#fff')), 'height': '75px'}">
                <div class="border-with-space" style="margin: 0.75rem 0.5rem;">
                  <a href="javascript:;" @click="is_refund_available ? ( row.is_allowable_return ? indexItemSelected(index) : false) : indexItemSelected(index)">
                    <div class="d-block text-center">{{ row.insert_time }}</div>
                    <div class="d-block text-center mt-3"><i class="flaticon2-plus text-dark"></i></div>
                  </a>
                </div>
                <div class="d-flex justify-content-between w-100">
                  <div style="margin: 0.75rem 0.5rem;">
                    <div class="d-block">
                      <h6>
                        <span v-if="is_refund_available && row.is_return" class="pl-2 pr-2">{{row.original_qty }} / {{ row.remaining_qty}}</span>
                        <a href="javascript:;" @click="is_refund_available ? ( row.is_allowable_return ? indexItemSelected(index) : false) : indexItemSelected(index)">
                          <span>{{ row.name }}</span>
                        </a>
                      </h6>
                    </div>
                    <div class="d-flex">
                      <span style="padding-top: 7px;min-width: 60px;">{{ row.sale_price }}</span>
                      <div class="d-flex mr-2 ml-2">
                        <i class="fa fa-arrow-down text-0C6F58" style="padding: 10px 10px 0;"></i>
                        <div class="input-group">
                          <input type="text" class="form-control form-control-sm" :disabled="(is_refund_available && !row.is_allowable_return)" v-model="row.discount_val" @input="setDiscountValPer($event, index)" placeholder="00.00" style="width: 80px;">
                          <div class="input-group-append" style="margin-left: -2px">
                            <button class="btn btn-sm 2" :disabled="(is_refund_available && !row.is_allowable_return)" @click="setDiscountType(index, 2)" :class="{'btn-primary' :row.discount_type == 2, 'btn-outline-primary':row.discount_type != 2}"><i class="fa fa-percent" style="font-size: 12px;padding-left:unset;"></i></button>
                            <button class="btn btn-sm 3" :disabled="(is_refund_available && !row.is_allowable_return)" @click="setDiscountType(index, 3)" :class="{'btn-primary' :row.discount_type == 3, 'btn-outline-primary':row.discount_type != 3}">{{ currency_code }}</button>
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                  <div class="d-flex" style="margin: 0.5rem 0.5rem;">
                    <div style="margin: 0 0.75rem 0.5rem;">
                      <div class="d-block text-center d-flex">
                        <a href="javascript:;" class="ml-3 mr-3 mt-2" v-if="row.is_electronic_scale" @click="showChangeQtyModal(index, row.qty)"><i class="fa fa-edit text-0C6F58"></i></a>
<!--                        <input v-model="row.qty" :disabled="(is_refund_available && !row.is_allowable_return) || row.is_electronic_scale" @input="changeQtyNormalInput(index, $event)" :min="0.001" type="number" class="form-control form-control-sm" style="text-align: center;width: 100px;"/>-->
<!--                        <b-form-spinbutton v-model="row.qty" :disabled="(is_refund_available && !row.is_allowable_return) || row.is_electronic_scale" @change="changeQty(index, $event)" :min="1" :max="row.is_committed ? '' : row.available_stock_qty" :step="getStepSpinButton(row.qty)" class="custom-spinbutton"></b-form-spinbutton>-->
                        <TheFormSpinbuttonWithInput v-if="row.is_return"
                            :id="'card-number'+index"
                            :index-in-list="index"
                            :in-value.sync="row.qty"
                            :step="getStepSpinButton(row.qty)"
                            :disabled="(is_refund_available && !row.is_allowable_return) || row.is_electronic_scale"
                            @handle-data="changeQtyNormalInput" :min="0" :max="row.remaining_qty"
                        />
                        <TheFormSpinbuttonWithInput v-else
                            :id="'card-number'+index"
                            :index-in-list="index"
                            :in-value.sync="row.qty"
                            :step="getStepSpinButton(row.qty)"
                            :disabled="(is_refund_available && !row.is_allowable_return) || row.is_electronic_scale"
                            @handle-data="changeQtyNormalInput" :min="0" :max="row.is_committed ? null : row.available_stock_qty"
                        />
                      </div>
                      <div class="d-block text-center" style="padding-top: 2px;padding-right: 33px;" v-if="row.is_electronic_scale">
                        <b class="text-FFB803" style="font-size: 16px;">{{ row.subtotal }}</b>
                      </div>
                      <div class="d-block text-center" style="padding-top: 2px;" v-else>
                        <b class="text-FFB803" style="font-size: 16px;">{{ row.subtotal }}</b>
                      </div>
                    </div>
                    <div style="margin: 0.2rem 0.5rem;">
                      <a v-if="is_refund_available && !row.is_return" href="javascript:;" @click="cloneSelectedItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.return')"><i class="fas fa-angle-down text-warning font-size-20px"></i></a>
                      <a v-else-if="is_refund_available && row.is_return" href="javascript:;" @click="removeItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.remove')"><i class="flaticon-delete text-danger"></i></a>
                      <a v-else-if="!is_refund_available && !row.is_return" href="javascript:;" @click="removeItem(index)" v-b-tooltip.hover.top :title="$t('point_of_sales.remove')"><i class="flaticon-delete text-danger"></i></a>
                      <a v-if="!is_refund_available && !row.is_return && $can('point_sales.update_pos_price')" href="javascript:;" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('price')" @click="addAction('price', index)"><i class="flaticon-coins text-0C6F58"></i></a>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-md-12 pb-5 pt-5 pl-0 pr-0 border-top-1-solid">
              <div class="row pb-4">
                <div class="text-center border-with-space col-md-3">
                  <h6>{{ is_refund_available ? $t('point_of_sales.total_current_bill_amount') : $t('point_of_sales.subTotal') }}</h6>
<!--                  <p class="mb-2">{{ (is_refund_available ? invoice_subtotal : total_items) }} {{ currency }}</p>-->
                  <p class="mb-2">{{ (total_items) }} {{ currency }}</p>
                </div>
                <div class="text-center border-with-space col-md-3">
                  <h6>{{ is_refund_available ? $t('point_of_sales.refund_discount') : $t('point_of_sales.discount') }}</h6>
                  <p class="mb-2">{{ total_dis ? total_dis : '0' }} {{ currency }}</p>
                </div>
                <div class="text-center col-md-3" :class="{'border-with-space': ((is_refund_available && is_apply_taxes_invoice_refund) || (!is_refund_available && is_apply_taxes_invoice))}">
                  <h6>{{ $t('point_of_sales.shipping') }}</h6>
                  <p class="mb-2">{{ shipping_val ? shipping_val : '0' }} {{ currency }}</p>
                </div>
                <template v-if="is_refund_available">
                  <div v-if="is_apply_taxes_invoice_refund" class="text-center col-md-3">
                    <h6>{{ $t('point_of_sales.tax') }}</h6>
                    <p class="mb-2">{{ total_tax ? total_tax : '0' }} {{ currency }}</p>
                  </div>
                </template>
                <template v-else-if="is_apply_taxes_invoice">
                  <div class="text-center col-md-3">
                    <h6>{{ $t('point_of_sales.tax') }}</h6>
                    <p class="mb-2">{{ total_tax ? total_tax : '0' }} {{ currency }}</p>
                  </div>
                </template>
              </div>
              <div class="col-md-12">
                <div class="pt-4 border-top-2-dotted">
                  <h5 class="mb-2">{{ is_refund_available ? $t('point_of_sales.refund_rotal') : $t('point_of_sales.total') }} {{ total_final }} {{ currency }}</h5>
                </div>
                <div class="mt-4" v-if="is_refund_available">
                  <h5 class="mb-2">{{ is_refund_available ? $t('point_of_sales.total_amount_before_return') : $t('point_of_sales.total') }} {{ invoice_total }} {{ currency }}</h5>
                </div>
              </div>


            </div>


          </div>
        </div>
        <div class="col-md-12 mb-5">
          <b-button variant="primary" class="w-100" :disabled="!activePaymentBtn" @click="(is_refund_available ? showRefundModal() : showPaymentModal())">{{ $t('payment') }}</b-button>
        </div>
      </div>
      <div class="col-md-6 pl-0 pr-0">
        <div class="card-title mb-1">
          <div class="form-group ml-0 mr-0 mb-0">
            <div class="d-flex mt-4">
              <!--              <div @click="home" class="input-group-prepend cursor-pointer">-->
              <!--                  <span class="input-group-text line-height-0 py-0 btn-primary" style="border-radius: 0;">-->
              <!--                      <span class="svg-icon">-->
              <!--                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">-->
              <!--                              <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"></path>-->
              <!--                          </svg>-->
              <!--                      </span>-->
              <!--                  </span>-->
              <!--              </div>-->

              <!--              <div>-->
              <!--                <select v-model="data.type" class="custom-select" style="border-radius:0;">-->
              <!--                  <option :value="row.id" v-for="(row , index) in searchBox" :key="index">-->
              <!--                    {{ row.name }}-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->
              <div class="d-block w-100">
                <button v-for="(row , index) in searchBox" :key="index" class="btn mr-2 ml-2" :class="data.type == row.id ? 'btn-primary' :'btn-light'" @click="data.type = row.id">{{ row.name }}</button>
              </div>

              <div class="input-group input-group-solid">
                <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
                </div>
                <input type="text" class="form-control" v-model="data.name" :placeholder="$t('search')" @keydown.enter="searchOnEnter">
              </div>


              <!--                <input type="text" class="form-control border-radius-0" v-model="data.name" :placeholder="$t('search')" @keydown.enter="searchOnEnter" aria-describedby="basic-addon2">-->
              <!--                <div class="input-group-append" @click="search">-->
              <!--                  <span class="btn btn-primary input-group-text line-height-0 py-0 border-radius-0">-->
              <!--                      <span class="svg-icon">-->
              <!--                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">-->
              <!--                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>-->
              <!--                          </svg>-->
              <!--                      </span>-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--                <div class="input-group-append" @click="reset">-->
              <!--                  <span class="btn btn-danger input-group-text line-height-0 py-0 border-radius-0">-->
              <!--                      <span class="svg-icon">-->
              <!--                          {{ $t('reset_search') }}-->
              <!--                      </span>-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--                <div class="input-group-append" @click="showProductInfo">-->
              <!--                                        <span class="btn input-group-text line-height-0 py-0" :class="{'btn-light': !show_product_info, 'btn-dark': show_product_info,}">-->
              <!--                                            <span class="svg-icon">-->
              <!--                                                <i class="fas fa-exclamation-circle" style="font-size: 20px"></i> {{ $t('product_information') }}-->
              <!--                                            </span>-->
              <!--                                        </span>-->
              <!--                </div>-->


            </div>
            <div class="mt-4 d-flex justify-content-between">
              <div>
                <label v-if="!is_specail_category" class="label label-light label-inline m-2 p-5 w-max-content cursor-pointer" @click="getItems">الكل</label>
                <label class="label label-light label-inline m-2 p-5 w-max-content cursor-pointer" v-for="row in categories[0]" @click="getItemsByCategory(row.id)" :key="row.id">{{ row.label }}</label>
              </div>
              <b-dropdown v-if="categories[1] && categories[1].length > 0" id="dropdown-categories" variant="light" :text="' '" ref="dropdown" menu-class="dropdown-categories-list" class="m-2">
                <label class="label label-light label-inline mr-2 ml-2 mb-2 mt-2 w-max-content cursor-pointer" v-for="row in categories[1]" @click="getItemsByCategory(row.id)" :key="row.id">{{ row.label }}</label>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="card-body" style="height: 519px;overflow-y: scroll;padding-top: 0;">
          <div v-if="show_loader" class="form-group row text-center justify-content-center" style="width: 100%;height: 100%;margin-top: 20px;">

            <div>
              <i class="fas fa-sync fa-spin" style="font-size: 75px"></i>
            </div>

          </div>
          <div v-if="!show_loader" class="form-group row">
            <div class="col-12 d-flex justify-content-between">
              <div>
                <button class="btn btn-primary btn-sm" v-if="pagination.current_page > 1" @click="previousPage">{{ $t('previous') }}</button>
              </div>
              <div>
                <button class="btn btn-primary btn-sm" v-if="pagination.last_page != pagination.current_page" @click="nextPage">{{ $t('next') }}</button>
              </div>

            </div>
            <!--            <template v-if="is_show_calc">-->
            <!--              <div class="col-md-12 pt-3">-->
            <!--                <div class="buttons">-->
            <!--                  <div class="button" @click="selectNumber('7')">7</div>-->
            <!--                  <div class="button" @click="selectNumber('8')">8</div>-->
            <!--                  <div class="button" @click="selectNumber('9')">9</div>-->
            <!--                  <div class="button" @click="restCalc">←</div>-->
            <!--                  <div class="button" @click="selectNumber('4')">4</div>-->
            <!--                  <div class="button" @click="selectNumber('5')">5</div>-->
            <!--                  <div class="button" @click="selectNumber('6')">6</div>-->
            <!--                  <div class="button">+/-</div>-->
            <!--                  <div class="button" @click="selectNumber('1')">1</div>-->
            <!--                  <div class="button" @click="selectNumber('2')">2</div>-->
            <!--                  <div class="button" @click="selectNumber('3')">3</div>-->
            <!--                  <div id="equal" class="button grid-h-2">{{ $t('enter') }}</div>-->
            <!--                  <div class="button grid-v-2" @click="selectNumber('0')">0</div>-->
            <!--                  <div class="button" @click="selectNumber('.')">.</div>-->
            <!--                </div>-->

            <!--              </div>-->
            <!--            </template>v-if="!is_show_calc"-->
            <template>
              <template v-if="isClient == true">
                <div class="col-md-3 pt-3 cursor-pointer">
                  <div class="card card-custom card-fit card-border cursor h-100" @click="showModal()" style="align-items: center;justify-content: center;">
                    <div class="card-header p-2">
                      <div class="card-title m-0">
                        <h3 class="card-label m-0">
                          {{ $t('add_client') }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 pt-3 cursor-pointer" v-for="row in customers" :key="row.id" >
                  <!--                                            :class="{'default-client': }"-->
                  <div class="card card-custom card-fit card-border cursor f-s-14" style="border-color: #E5E5E5;">
<!--                    <div class="card-header p-2">-->
<!--                      <div class="card-title m-0">-->
<!--                        <h5 class="card-label m-0" style="font-size: 1.1rem;">-->
<!--                          {{ row.name ? row.name : '' }}-->
<!--                        </h5>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="card-body p-2">
                      <div @click="switchOnProducts(row.name, row)">
                        <h5 style="font-size: 1.1rem;">
                          {{ row.name ? row.name : '' }}
                        </h5>
                        <span class="d-block d-flex mb-2"><i class="fa fa-phone-alt"></i> <span class="d-block hidden-line" style="margin: 0 5px;direction: ltr;">{{ row.phone }}</span></span>
                        <span class="d-block d-flex"><i class="fa fa-envelope"></i> <span class="d-block hidden-line" style="margin: 0 5px;direction: ltr;">{{ row.email }}</span></span>

                      </div>
                      <div class="d-flex justify-content-end">
                        <div class="m-2"><a href="javascript:;" @click="showModal(row.id)"><i class="fa fa-pen" style="color:#0c6f58;font-size: 12px;"></i></a></div>
                        <div class="m-2"><a href="javascript:;" @click="showCustomerBranchModal(row.id)"><i class="fa fa-code-branch" style="color:#0c6f58;font-size: 12px;"></i></a></div>

                      </div>

                    </div>

                  </div>
                </div>
              </template>

              <!--                <template v-if="isClient == false && data.type == 1">-->
              <!--                  <div class="col-md-3 pt-3" v-for="row in categories" :value="row.id" :key="row.id">-->
              <!--                    <div class="card card-custom cursor" @click="getItemsByCategory(row.id)">-->
              <!--                      <div class="card-image">-->
              <!--                        &lt;!&ndash;                                            <b-card-img :src="row.image_url" alt="Image" bottom></b-card-img>&ndash;&gt;-->
              <!--                        <img :src="row.image_url" class="img-fluid-item" alt="Image">-->
              <!--                      </div>-->
              <!--                      <div class="card-header p-2">-->
              <!--                        <div class="card-title d-flex">-->
              <!--                          <h3 class="card-label fs-14 mb-0 p-1">{{ row.label }}</h3>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </template>-->

              <template v-if="isClient == false && data.type == 1">
                <div class="col-md-3 pt-3" v-for="row in items" :key="'a'+row.id">
                  <div class="card" style="padding: 5px;">
                    <!-- <span class=" btn btn-info" style="top: 75px;" @click="getItemByBarcodeScanner(row)">
                      <i class="fa fa-barcode" aria-hidden="true"></i>
                    </span> -->
                    <!--                      @click="(show_product_info? selectToShowProductInfo(row.id) : selectItem(row))"-->
                    <a href="javascript:;" @click="isUsedToBeActive(row) ? selectItem(row) : false">
                      <div class="card-image">
                        <img :src="row.image_url" class="img-fluid-item" alt="Image">
                      </div>
                      <div class="card-title mb-2">
                        <h3 class="d-block fs-12 mb-0 p-1">{{ row.name }}</h3>
                        <div class="d-flex justify-content-between mt-4">
                          <span class="d-block">{{ $t('qty') }}: {{ row.available_stock_qty }}</span>
                          <span class="d-block">{{ row.sale_price }} {{ currency }}</span>
                        </div>
                      </div>
                    </a>
                      <div class="d-block text-center mt-4">
                        <b-form-spinbutton :disabled="!isUsedToBeActive(row)" @change="increaseDecreaseFromItemsList(row, $event)" :value="getQtyFromItemCart(row)" :step="getStepSpinButton(getQtyFromItemCart(row))" :min="1" :max="row.is_committed ? '' : row.available_stock_qty" class="custom-spinbutton"></b-form-spinbutton>
                      </div>
                      <div class="d-block text-center mt-4" :style="{'position': 'absolute','top': '50%',[dir == 'rtl'?'left':'right']: '5px'}">
                        <a href="javascript:;" @click="selectToShowProductInfo(row.id)"><i class="fa fa-eye text-FFB803"></i></a>
                      </div>

                  </div>
                </div>
              </template>

              <template v-if="isClient == false && data.type == 2">
                <div class="col-md-3 pt-3" v-for="row in invoices" :key="'b'+row.id">
                  <div class="card">
                    <div class="card-body p-4">

                      <h5 class="card-title f-s-14" style="font-weight: 700;">#{{ row.invoice_code }}</h5>
                      <h6 class="card-subtitle mb-2 f-s-14">{{ row.customer_name }}</h6>
                      <p class="card-text f-s-14">{{ parseToShortFloat(row.invoice_total) }} {{ row.currency_code }}</p>
                      <p class="card-text f-s-14">{{ row.invoice_date }}</p>

                      <div v-if="isAllowInvoiceReturn && row.is_allow_invoice_return">
                        <div class="d-flex justify-space-between">
                          <b-button variant="secondary" class="m-1" @click="showInvoiceInformationModal(row.id)"><i class="fa fa-eye text-0C6F58 p-0"></i></b-button>
                          <a href="javascript:;" @click="makeRefundAvailable(row.id)" class="btn btn-danger m-1">{{ $t('partial_refund') }}</a>
                        </div>
                        <div class="d-flex justify-space-between mt-2">
                          <a v-if="default_print_invoice_size == 'a4' && row.is_apply_taxes_invoice" :href="`/print/sales-tax-invoice/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                          <a v-else-if="default_print_invoice_size == 'a4'" :href="`/print/sales/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                          <a v-else :href="`/print/sales-80mm-invoice/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                          <a href="javascript:;" @click="makeRefundAvailable(row.id, true)" class="btn btn-warning m-1">{{ $t('refund_fully_invoice') }}</a>
                        </div>

                      </div>
                      <div v-else>
                        <div class="d-flex justify-space-between">
                          <b-button variant="secondary" class="m-1" @click="showInvoiceInformationModal(row.id)"><i class="fa fa-eye text-0C6F58 p-0"></i></b-button>
                          <a v-if="default_print_invoice_size == 'a4' && row.is_apply_taxes_invoice" :href="`/print/sales-tax-invoice/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                          <a v-else-if="default_print_invoice_size == 'a4'" :href="`/print/sales/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                          <a v-else :href="`/print/sales-80mm-invoice/${row.id}/print`" target="_blank" class="btn btn-secondary m-1 d-flex align-items-center"><i class="fa fa-print text-0C6F58 p-0"></i></a>
                        </div>
                      </div>
                    </div>
<!--                    <template v-if="isAllowInvoiceReturn && row.is_allow_invoice_return">-->
<!--&lt;!&ndash;                    <template v-if="isAllowInvoiceReturn">&ndash;&gt;-->
<!--                      <a href="javascript:;" @click="makeRefundAvailable(row.id)" class="btn btn-danger col-12 rounded-0">{{ $t('partial_refund') }}</a>-->
<!--                      <a href="javascript:;" @click="makeRefundAvailable(row.id, true)" class="btn btn-warning col-12 rounded-0 mt-2">{{ $t('refund_fully_invoice') }}</a>-->
<!--                      &lt;!&ndash;                        <a href="javascript:;" @click="showRefundFullyInvoiceModal(row.id, row.invoice_total_without_shipping)" class="btn btn-warning col-12 rounded-0 mt-2">{{ $t('refund_fully_invoice') }}</a>&ndash;&gt;-->
<!--                    </template>-->
                  </div>
                </div>
              </template>

            </template>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="modal" size="lg" hide-footer :title="$t(customer_id_for_editing? 'customers.update_customer' : 'customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer" :type="type" :customer-id.sync="customer_id_for_editing"></customer-form>
    </b-modal>
    <b-modal ref="customerBranchModal" size="lg" hide-footer :title="$t('customers.customer_branches')">
      <customer-branch-form @hide-modal="hideCustomerBranchModal()" @handling-data="fetchCustomersAfterSave()" :customer-id.sync="current_customer_id"></customer-branch-form>
    </b-modal>
    <b-modal ref="paymentModal" size="xl" hide-footer hide-header :body-class="'p-0 overflow-hidden-with-radius'" :title="$t('point_of_sales.payment')">
      <pos-payment-form v-if="is_refund_available == false"
                        @hide-modal="hidePaymentModal()"
                        @hide-save-modal="hidePrintPaymentModal"
                        @reset-list="resetList()"
                        @print-showing-payed-invoice="printShowingPayedInvoiceScreen()"
                        :items="selected_items"
                        :total-dis="total_dis"
                        :total-items="total_items"
                        :shipping-val="shipping_val"
                        :customer="customer"
                        :currency-id="currency_id"
                        :currency-name="currency"
                        :total-tax="total_tax"
                        :total-final="total_final"
                        :notes="notes"
                        :session-id="session_id"
                        :is-apply-taxes-invoice="is_apply_taxes_invoice"
                        :total-out-tax="total_out_tax"
                        :total-tax-type-val="total_tax_type_val"
                        :discount-totals="{total_amount_before_discount: total_amount_before_discount, total_amount_after_discount: total_amount_after_discount, invoice_total_after_taxes: invoice_total_after_taxes, invoice_total_before_taxes: invoice_total_before_taxes}"
                        :payment-methods-list.sync="paymentMethodsList"
                        :request-invoice.sync="request_invoice"
                        :print-type.sync="default_print_invoice_size"
      ></pos-payment-form>
    </b-modal>
    <b-modal ref="refundModal" size="xl" hide-footer hide-header :body-class="'p-0 overflow-hidden-with-radius'" :title="$t('point_of_sales.payment')">
      <pos-refund-form v-if="is_refund_available == true"
                       @hide-modal="hideRefundModal()"
                       @hide-save-modal="hidePrintRefundModal()"
                       @reset-list="resetList()"
                       :items="selected_items"
                       :total-dis="total_dis"
                       :total-items="total_items"
                       :shipping-val="shipping_val"
                       :customer="customer"
                       :currency-id="currency_id"
                       :currency-name="currency"
                       :total-tax="total_tax"
                       :previous-invoice-total="invoice_total"
                       :invoice-id="invoice_id"
                       :session-id="session_id"
                       :total-final="total_final"
                       :is-apply-taxes-invoice="is_apply_taxes_invoice_refund"
                       :total-out-tax="total_out_tax"
                       :total-tax-type-val="total_tax_type_val"
                       :discount-totals="{total_amount_before_discount: total_amount_before_discount, total_amount_after_discount: total_amount_after_discount, invoice_total_after_taxes: invoice_total_after_taxes, invoice_total_before_taxes: invoice_total_before_taxes}"
                       :payment-methods-list.sync="paymentMethodsList"
                       :print-type.sync="default_print_refund_size"
      ></pos-refund-form>
    </b-modal>
    <b-modal ref="productInformationModal" size="custom-xl" hide-footer :title="$t('product_information')">
      <product-information @hide-modal="hideProductInformationModal()" :id="productId" :currency-code.sync="currency"></product-information>
    </b-modal>
    <b-modal ref="notesCartModal" size="lg" hide-footer :title="$t('notes')">
      <div class="col-lg-12 mb-5">
        <textarea v-model="notes" class="form-control"></textarea>
      </div>
      <div class="col-lg-12 mb-5 d-flex justify-content-center">
        <button class="btn btn-primary" @click="hideNotesCartModal">{{ $t('confirm') }}</button>

      </div>
    </b-modal>
    <b-modal ref="changeQtyModal" size="md" hide-footer :title="$t('edit_qty')">
      <div class="col-lg-12 mb-5">
        <input type="number" v-model="cart_qty" class="form-control">
      </div>
      <div class="col-lg-12 mb-5 d-flex justify-content-center">
        <button class="btn btn-primary" @click="changeQtyModal">{{ $t('confirm') }}</button>
      </div>
    </b-modal>

    <b-modal ref="calcModal" size="sm" hide-footer hide-header no-close-on-backdrop :title="''">
      <div class="d-flex justify-content-end">
        <a href="javascript:;" class="text-dark" @click="hideCalcModal">
          <i class="fa fa-times text-dark"></i>
        </a>
      </div>
      <div v-if="item_action == 'dis_val' || item_action == 'dis_per'" class="pt-3 text-center">
        <b-button-group>
          <b-button @click="addAction('dis_per')" :variant="item_action == 'dis_per' ? 'primary': 'outline-primary'">خصم نسبة</b-button>
          <b-button @click="addAction('dis_val')" :variant="item_action == 'dis_val' ? 'primary': 'outline-primary'">خصم قيمة</b-button>
        </b-button-group>
      </div>
      <div class="pt-3">
        <div class="text-center mb-7"><span style="border-bottom: 1px solid rgb(0, 0, 0);font-size: 30px;min-width: 54px !important;display: block;margin: 0 auto;width: max-content;">{{ num_calc ? num_calc : '0.0' }}</span></div>
        <div class="buttons">
          <div class="button" @click="selectNumber('9')">9</div>
          <div class="button" @click="selectNumber('8')">8</div>
          <div class="button" @click="selectNumber('7')">7</div>
          <div class="button" @click="restCalc">←</div>
          <div class="button" @click="selectNumber('6')">6</div>
          <div class="button" @click="selectNumber('5')">5</div>
          <div class="button" @click="selectNumber('4')">4</div>
          <div class="button" @click="selectNumber('3')">3</div>
          <div class="button" @click="selectNumber('2')">2</div>
          <div class="button" @click="selectNumber('1')">1</div>
          <div id="equal" class="button grid-h-3 d-flex justify-content-center align-items-center" @click="selectNumber('enter')">
            <i class="fas fa-level-down-alt text-white" style="font-size: 24px;"></i>
          </div>
          <div class="button grid-v-2" @click="selectNumber('0')">0</div>
          <div class="button" @click="selectNumber('.')">.</div>
        </div>
        <div class="text-center pt-5" v-if="item_action == 'price'">
          <button class="btn btn-primary" @click="selectNumber('confirm_with_update')">{{$t('confirm_with_update')}}</button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="refundFullyInvoiceModal" size="lg" hide-footer :title="$t('refund_fully_invoice')">
      <div class="row">
        <div class="col-md-12 pt-3">
          <div class="row mb-5">
            <div class="col-md-6"><b>{{ $t('payment_method') }}</b></div>
            <div class="col-md-6"><b>{{ method1_name }}</b></div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6"><b>{{ $t('point_of_sales.refund_amount') }}</b></div>
            <div class="col-md-6"><input type="number" class="form-control" v-model="refund_amount"></div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12 text-center">
              <b-button variant="primary" @click="confirmedRefund">{{ $t('point_of_sales.confirm') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="invoiceInformationModal" lazy size="lg" hide-footer :title="$t('invoice_details')">
      <view-invoice-information :invoice-id.sync="view_invoice_id" @hide-modal="hideInvoiceInformationModal"/>
    </b-modal>

  </div>
</template>
<script>

import 'vue-multiselect/dist/vue-multiselect.min.css';

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";
import POSPaymentForm from "@/view/content/forms/POSPaymentForm";
import POSRefundForm from "@/view/content/forms/POSRefundForm";
import ProductInformationModal from "@/view/content/modals/ProductInformationModal";
import Vue from "vue";
import dataTypeNumbers from '@/core/config/mix/dataTypeNumbers';
import ViewInvoiceInformation from "@/view/pages/modules/pos/pointOfSales/ViewInvoice.vue";
import CustomerBranchForm from "@/view/content/forms/CustomerBranchForm.vue";
import TheFormSpinbuttonWithInput from "@/view/content/widgets/inputs/TheFormSpinbuttonWithInput.vue";

export default {
  name: "index-point-of-sales",
  components: {TheFormSpinbuttonWithInput, CustomerBranchForm, 'view-invoice-information': ViewInvoiceInformation, 'customer-form': CustomerForm, 'pos-payment-form': POSPaymentForm, 'pos-refund-form': POSRefundForm, 'product-information': ProductInformationModal},
  props: {
    innerUser: null,
    innerCurrency: null,
    currencyId: null,
    currencyCode: null,
    sessionId: null,
    indexScreen: null,
    isDisabled: {default: true, type: Boolean},
    isEnabled: {default: true, type: Boolean},
    isAllowInvoiceReturn: {default: true, type: Boolean},
    handlingData: {type: Function},
    setTypeInvoice: {type: Function},
    printShowingPayedInvoice: {type: Function},
    refreshSession: {type: Function},
    propsData: {type: Object},
  },
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRouteInvoice: 'sales/sales_invoices',
      mainRefundFullyInvoiceRoute: 'pos/point-sale/all/refund',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      data: {
        name: null,
        type: 1,
        user_id: null,
        barcode: null
      },
      lang: this.$i18n.locale,
      items_list: [],

      searchBox: [
        {
          "id": 1,
          "name": this.$t('products'),
        },
        {
          "id": 2,
          "name": this.$t('invoice'),
        },
        {
          "id": 3,
          "name": this.$t('customer'),
        }
      ],
      categories: [],
      customers: [],
      items: [],
      scan: [],
      invoices: [],
      selected_items: [],
      total_items: 0,
      total_dis: 0,
      total_final: 0,
      shipping_val: 0,
      total_tax: 0,
      total_out_tax: 0,
      invoice_total: 0,
      invoice_subtotal: 0,
      index_item_selected: null,
      item_action: null,
      total_tax_type_val: [],

      invoice_url: null,
      client_name: this.$t('pos_client'),
      type: 'point_of_sale',
      user: [],
      currency: 'SAR',
      currency_id: null,
      currency_code: null,
      date_now: '',

      show_loader: false,
      isClient: false,
      num_calc: '',
      is_show_calc: false,
      customer: null,
      show_product_info: false,
      productId: null,
      is_refund_available: false,
      invoice_id: null,
      session_id: null,
      refund_amount: null,
      is_cash: '',
      payment_method: '',
      method1_name: '',
      notes: '',

      inner_more_details: {
        discount_value: 0,
        discount_type: 2,
      },
      is_show_validation_dis_value: false,
      is_apply_taxes_invoice: false,
      is_apply_taxes_invoice_refund: false,
      total_amount_before_discount: 0,
      total_amount_after_discount: 0,
      invoice_total_after_taxes: 0,
      invoice_total_before_taxes: 0,
      piece_no: 0,

      pagination: {
        current_page: 1,
        last_page: 0,
      },
      paymentMethodsList: [],
      request_get_items_invoice: false,
      cart_qty: 0,
      index_cart_qty: 0,
      dir: this.$i18n.locale == 'ar'? 'rtl': 'ltr',
      is_specail_category: false,
      request_invoice: false,
      request_update_price_of_item: true,
      view_invoice_id: null,
      customer_id_for_editing: null,
      current_customer_id: null,
      default_print_invoice_size: '80mm',
      default_print_refund_size: '80mm',
    }
  },
  computed: {
    activePaymentBtn() {
      if (this.is_refund_available) {
        let _selected_items_returned = this.selected_items.filter(row => row.is_return == true);
        if (_selected_items_returned.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.selected_items.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isApplyTaxesInvoice() {
      let _status = this.is_apply_taxes_invoice;
      return _status;
    },
  },
  watch: {
    // "data.name": function (val) {
    //   if (!val) {
    //     this.categories = this.getCategories();
    //   }
    // },
    isEnabled: function (val) {
      if (val) {
        this.initialOnClickKeyboard();
        this.$barcodeScanner.init(this.onBarcodeScanned);
      } else {
        this.index_item_selected = null;
      }

    },
    // selected_items: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.calcItemsSelected();
    //
    //
    //     }
    //   },
    //   deep: true
    // },
    is_show_calc: function (val) {
      if (val) {
        this.showCalcModal();
      } else {
        this.hideCalcModal();
        this.num_calc = '';
      }
    },
    'data.type': function (val) {
      this.initCurrency();
      this.initForTotals();
      this.data.name = null;
      this.resetPagination();
      if (val == 3) {
        this.getClient();
        this.$emit('set-type-invoice', false);
      } else {
        this.is_refund_available = false;
        this.selected_items = [];
        this.$emit('refresh-session', false);
        this.searchItems();
        this.isClient = false;
      }
    },
    isClient: function (val) {
      if (val) {
        this.data.type = 3;
      }
    }
  },

  methods: {
    // onLogout() {
    //     this.$store
    //         .dispatch(LOGOUT)
    //         .then(() => this.$router.push({name: "login"}));
    // },
    // printInvoice() {
    //     let iframe = document.getElementById('invoice');
    //     iframe.contentWindow.print();
    // },
    ...dataTypeNumbers,
    nextPage() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page = this.pagination.current_page + 1;
      }
      this.search();
    },
    previousPage() {
      if (this.pagination.current_page > 1) {
        this.pagination.current_page = this.pagination.current_page - 1;
      }
      this.search();
    },
    resetPagination() {
      this.pagination = {
        current_page: 1,
        last_page: 0,
      }
    },
    getClient() {
      this.isClient = true;
      this.selected_items = [];
      this.total_items = 0;
      this.total_final = 0;
      this.total_dis = 0;
      this.shipping_val = 0;
      this.invoice_total = 0;
      // this.client_name = this.$t('pos_client');
      this.is_show_calc = false;
      this.is_refund_available = false;
      this.getCustomers();
    },
    switchOnProducts(name, row) {
      this.client_name = name;
      this.customer = row;
      this.isClient = false;
      this.data.type = 1;
    },
    getDataAfterCreateNewCustomer(obj) {
      this.customer = obj;
      this.client_name = obj.fullname;
      // let promise = this.getCustomers();
      this.getCustomers();
      this.isClient = false;
      this.data.type = 1;
    },
    fetchCustomersAfterSave() {
      this.getCustomers();
    },
    async search() {
      if (this.data.type == 1 || this.data.type == 2) {
        this.isClient = false;
      }
      if (this.isClient) {
        this.getCustomers();
      } else {
        return this.searchItems();
      }
    },
    searchOnEnter() {
      // Trigger search if the Enter key is pressed
      this.resetPagination();
      this.search();

    },
    searchOnEnterInput() {
      if (this.data.barcode)
        this.getItemByBarcodeScanner(this.data.barcode);
    },
    async searchItems(_update_item_action = true) {
      this.showLoader();
      if (_update_item_action){
        this.is_show_calc = false;
        this.item_action = null;
      }
      await ApiService.get(`${this.mainRouteDependency}/items_pos`, {
        params: {
          session_id: this.session_id,
          page: this.pagination.current_page,
          ...this.data
        }
      }).then((response) => {
        if (this.data.type == 1) {
          this.items = response.data.data.data;
          // this.categories = [];
          this.invoices = [];
        } else {
          // this.categories = [];
          this.items = [];
          this.invoices = response.data.data.data;
          this.isClient = false;
        }
        this.pagination.last_page = response.data.data.last_page
        this.hideLoader();
      });
    },
    showLoader() {
      this.show_loader = true;
    },
    hideLoader() {
      this.show_loader = false;
    },
    showModal(customer_id = null) {
      this.customer_id_for_editing = customer_id;
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showCustomerBranchModal(customer_id = null) {
      this.current_customer_id = customer_id;
      this.$refs['customerBranchModal'].show()
    },
    hideCustomerBranchModal() {
      this.$refs['customerBranchModal'].hide();
      this.current_customer_id = null;
    },
    showCalcModal() {
      this.$refs['calcModal'].show()
    },
    hideCalcModal() {
      this.is_show_calc = false;
      this.$refs['calcModal'].hide();
    },
    showPaymentModal() {
      this.disabledOnClickKeyboard();
      this.$refs['paymentModal'].show();
    },
    hidePaymentModal() {
      this.initialOnClickKeyboard();
      this.$refs['paymentModal'].hide();
    },
    hidePrintPaymentModal(object_data) {
      this.$emit('handling-data', object_data);
      this.$refs['paymentModal'].hide();
      this.resetCommonData();
    },
    showRefundModal() {
      this.disabledOnClickKeyboard();
      this.$refs['refundModal'].show();
    },
    hideRefundModal() {
      this.initialOnClickKeyboard();
      this.$refs['refundModal'].hide();
    },
    hidePrintRefundModal() {
      this.$emit('handling-data', 'refund');
      this.$refs['refundModal'].hide();
      this.resetCommonData();
      this.initCurrency();
      this.initForTotals();
    },
    showProductInformationModal() {
      this.$refs['productInformationModal'].show();
    },
    hideProductInformationModal() {
      this.$refs['productInformationModal'].hide();
    },
    showNotesCartModal() {
      this.$refs['notesCartModal'].show();
    },
    hideNotesCartModal() {
      this.$refs['notesCartModal'].hide();
    },
    showChangeQtyModal(index, qty = 0) {
      this.index_cart_qty = index;
      this.cart_qty = qty;
      this.$refs['changeQtyModal'].show();
    },
    hideChangeQtyModal() {
      this.$refs['changeQtyModal'].hide();
      this.index_cart_qty = null;
      this.cart_qty = 0;
    },
    showInvoiceInformationModal(id) {
      this.view_invoice_id = id;
      this.$refs['invoiceInformationModal'].show();
    },
    hideInvoiceInformationModal() {
      this.$refs['invoiceInformationModal'].hide();
      this.view_invoice_id = null;
    },
    reset() {
      this.data.name = null;
      // this.categories = this.getCategories();
      this.getItems();
      this.data.type = 1;
      this.invoices = [];
      this.getCustomers();
    },
    resetCommonData() {
      this.total_out_tax = 0;
      this.total_tax_type_val = [];
    },
    getCustomers() {
      this.showLoader();
      ApiService.get(`${this.mainRouteDependency}/customers_default`, {params: {filter: this.data.name, page:this.pagination.current_page}}).then((response) => {
        this.customers = response.data.data.data
        this.pagination.last_page = response.data.data.last_page;
        // response.data.data.map((row) => {
        //   if (row.type == 1) {
        //     this.switchOnProducts(row.name, row);
        //   }
        // })

        this.hideLoader();
      });
    },
    getCategories() {
      if (this.data.type == 1) {
        this.showLoader();
        ApiService.get(`${this.mainRouteDependency}/categories_sale`).then((response) => {
          this.categories[0] = response.data.data.filter((row, index) => {
            if (index <= 4) {
              return row;
            }
          });
          this.categories[1] = response.data.data.filter((row, index) => {
            if (index > 4) {
              return row;
            }
          });

          this.hideLoader();
        });
      }

    },
    // getDefaultCustomer() {
    //   ApiService.get(`${this.mainRouteDependency}/pos_default_customer`).then((response) => {
    //     this.switchOnProducts(response.data.data.name, response.data.data)
    //   });
    // },
    getItemsByCategory(id) {
      ApiService.get(`${this.mainRouteDependency}/items_by_category/${id}`).then((response) => {
        this.items = [];
        this.items = response.data.data;
        // this.categories = [];
      });
    },
    getItems() {
      this.showLoader();
      ApiService.get(`${this.mainRouteDependency}/items_pos`).then((response) => {
        this.items = response.data.data.data;
        this.pagination.last_page = response.data.data.last_page
        this.hideLoader();
      });
    },
    // getPOSSetting() {
    //     this.showLoader();
    //     ApiService.get(this.mainRouteDependency + '/pos_setting').then((response) => {
    //         // if (response.data.data.client_name) {
    //         //     this.switchOnProducts(response.data.data.client_name, response.data.data.client);
    //         // }
    //         this.hideLoader();
    //     });
    // },
    getItemsInvoice(id, refund_all) {
      if(this.request_get_items_invoice){
        return;
      }

      ApiService.get(this.mainRouteInvoice + '/' + id).then((response) => {
        if (response.data.data.items_list) {
          let _invoice_id = response.data.data.id;
          let _customer_id = response.data.data.customer_id;
          let _inventory_id = response.data.data.inventory_id;
          // this.invoice_total = response.data.data.invoice_total;
          this.invoice_total = response.data.data.invoice_total_without_shipping;
          this.total_tax = 0;
          this.invoice_id = response.data.data.id;

          this.customer_id = response.data.data.customer_id;
          this.client_name = response.data.data.customer ? response.data.data.customer.name : this.$t('pos_client');
          this.customer = response.data.data.customer;
          this.is_apply_taxes_invoice_refund = response.data.data.is_apply_taxes_invoice;

          this.currency_id = response.data.data.currency_id;
          this.currency = response.data.data.currency_code;
          this.selected_items = [];
          response.data.data.items_list.forEach((row) => {
            // let _tax_applied = (row.tax_applied && row.tax_applied[0]) ? row.tax_applied[0] : null;
            // let _include_tax = "0";
            // if (_tax_applied){
            //   _include_tax = _tax_applied.include_tax;
            // }

            let _remaining_qty = (parseFloat(row.qty) -  parseFloat(row.return_qty));
            let _sale_price = this.parseToShortFloatFixed((row.subtotal_after_discount ? (parseFloat(row.subtotal_after_discount) / row.qty) : 0));
            let _amount_excluded_tax = this.parseToShortFloatFixed((row.amount_excluded_tax ? ((parseFloat(row.amount_excluded_tax) / row.qty) * _remaining_qty) : 0));
            let _amount_tax = this.parseToShortFloatFixed((row.amount_tax ? ((parseFloat(row.amount_tax) / row.qty) * _remaining_qty) : 0));




            this.selected_items.push({
              id: row.id,
              item_id: row.item ? row.item.id : 0,
              invoice_id: _invoice_id,
              customer_id: _customer_id,
              inventory_id: _inventory_id,
              name: row.item ? row.item.name : '',
              // sale_price: row.unit_price,
              sale_price: _sale_price,
              discount_type: 1,
              discount_val: 0,
              dis_val: 0,
              dis_per: 0,
              // subtotal: row.subtotal_before_tax,
              subtotal: refund_all ? _remaining_qty * _sale_price : 0,
              subtotal_before_tax: refund_all ? _remaining_qty * _sale_price : 0,
              subtotal_after_tax: refund_all ? (parseFloat(_remaining_qty * _sale_price) + parseFloat(_amount_excluded_tax)) : 0,
              qty: (refund_all) ? _remaining_qty : 0,
              original_qty: row.qty,
              remaining_qty: _remaining_qty,
              total_taxes: refund_all ? _amount_tax : 0,
              tax: row.tax,
              include_taxes: row.include_taxes,

              subtotal_before_discount: row.subtotal_before_discount,
              subtotal_after_discount: row.subtotal_after_discount,
              amount_excluded_tax: refund_all ? _amount_excluded_tax : 0,
              amount_tax: refund_all ? _amount_tax : 0,

              // tax_2: row.tax_2,
              tax_val: refund_all ? _amount_tax : 0, //(row.subtotal * (row.amount_tax ? (parseFloat(row.amount_tax) / 100) : 0)).toFixed(2) : 0,
              is_return: true,
              is_allowable_return: row.is_allowable_return,
              is_electronic_scale: row.is_electronic_scale,

            });

            this.request_get_items_invoice = true;
          });
          // if (refund_all) {
            // this.selected_items.forEach((row, index)=>{
            //   if (this.is_apply_taxes_invoice_refund){
            //     this.getItemTaxCredit(index)
            //   }else{
            //     this.getItemPriceRefund(index)
            //   }
            // })
          this.request_get_items_invoice = false;
            this.calcItemsSelected();
          // }

        }
      });
    },

    /**
     * this functions used in items list
     * to increase and decrease quantities
     * @param row
     * @param event
     */
    increaseDecreaseFromItemsList(row, event) {
      let _ids = [];
      _ids = this.selected_items.map((row) => {
        return row.id;
      });
      if (_ids.includes(row.id)) {
        let _index = this.selected_items.findIndex(item => item.id == row.id);
        this.changeQty(_index, event);
      } else {
        this.selectItem(row)
      }
    },

    selectItem(row, info = null) {
      if (!this.customer) {
        return false;
      }

      if (!row.sale_price) return;
      let _ids = [];
      _ids = this.selected_items.map((row) => {
        return row.id;
      });

      if (_ids.includes(row.id)) {
        let _index = this.selected_items.findIndex(item => item.id == row.id);

        if (parseFloat(this.selected_items[_index].qty) >= parseFloat(row.available_stock_qty) && row.is_committed == 0) {
          return false;
        }

        if ((parseFloat(row.available_stock_qty) > parseFloat(this.selected_items[_index].qty) && parseFloat(row.sale_price) > 0) || (parseFloat(row.sale_price) > 0 && row.is_committed == 1)) {
          if (info) {
            this.selected_items[_index].qty = parseFloat(this.selected_items[_index].qty) + parseFloat(info.qty);
            this.selected_items[_index].is_electronic_scale = row.is_electronic_scale ? row.is_electronic_scale : false;
            if (this.selected_items[_index].is_electronic_scale) {
              this.selected_items[_index].item_sales_barcodes.push(row.item_sales_barcode);
            }
          } else {
            this.selected_items[_index].qty = parseFloat(this.selected_items[_index].qty) + 1;
            this.selected_items[_index].is_electronic_scale = row.is_electronic_scale ? row.is_electronic_scale : false;
            if (this.selected_items[_index].is_electronic_scale) {
              this.selected_items[_index].item_sales_barcodes.push(row.item_sales_barcode);
            }
          }

          this.selected_items[_index].subtotal = this.parseToShortFloatFixed(this.selected_items[_index].qty * (this.selected_items[_index].sale_price ? parseFloat(this.selected_items[_index].sale_price) : 0));
          this.selected_items[_index].tax_val = this.parseToShortFloatFixed(this.selected_items[_index].subtotal * (this.selected_items[_index].total_taxes ? (parseFloat(this.selected_items[_index].total_taxes) / 100) : 0));
          // this.calcItemsSelected();

          /**
           * get tax for item
           */
          this.changeQty(_index, this.selected_items[_index].qty);
          // this.getTaxValue(_index);
          // this.index_item_selected = _index;
        } else {
          let _row = row;
          _row.qty = info ? (parseFloat(_row.qty) + parseFloat(info.qty)) : 1;
          _row.is_electronic_scale = row.is_electronic_scale ? row.is_electronic_scale : false;

          if (_row.is_electronic_scale) {
            _row.item_sales_barcodes.push(row.item_sales_barcode);
          }

          _row.dis_per = 0;
          _row.dis_val = 0;
          _row.discount_type = 2;
          _row.discount_val = 0;
          _row.amount_tax = 0;

          _row.amount_excluded_tax = 0;
          _row.amount_included_tax = 0;
          _row.subtotal_before_tax = 0;
          _row.subtotal_after_tax = 0;
          _row.total_item_after_discount_with_excluded = 0;

          _row.tax_applied = [];
          _row.unit_price = _row.sale_price;
          _row.subtotal = info ? info.price : _row.qty * parseFloat(_row.sale_price);
          _row.tax_val = this.parseToShortFloatFixed(_row.subtotal * (_row.total_taxes ? (parseFloat(_row.total_taxes) / 100) : 0));
          _row.subtotal_before_discount = _row.subtotal;
          _row.subtotal_after_discount = _row.subtotal;


          this.selected_items.push(_row);

          let _index = this.selected_items.findIndex(item => item.id == row.id);

          /**
           * get tax for item
           */
          this.getTaxValue(_index);
          // this.index_item_selected = _index;
        }
      } else {
        if ((row.available_stock_qty > 0 && parseFloat(row.sale_price) > 0) || (parseFloat(row.sale_price) > 0 && row.is_committed == 1)) {
          let _row = row;
          _row.qty = info ? (parseFloat(_row.qty ? _row.qty : 0) + parseFloat(info.qty ? info.qty : 0)) : 1;
          _row.is_electronic_scale = row.is_electronic_scale ? row.is_electronic_scale : false;
          _row.item_sales_barcodes = [];
          if (_row.is_electronic_scale) {
            _row.item_sales_barcodes.push(row.item_sales_barcode);
          }
          _row.dis_per = 0;
          _row.dis_val = 0;
          _row.discount_type = 2;
          _row.discount_val = 0;
          _row.amount_tax = 0;

          _row.amount_excluded_tax = 0;
          _row.amount_included_tax = 0;
          _row.subtotal_before_tax = 0;
          _row.subtotal_after_tax = 0;
          _row.total_item_after_discount_with_excluded = 0;

          _row.tax_applied = [];
          _row.unit_price = _row.sale_price;
          _row.subtotal = info ? info.price : _row.qty * parseFloat(_row.sale_price);
          _row.tax_val = this.parseToShortFloatFixed(_row.subtotal * (_row.total_taxes ? (parseFloat(_row.total_taxes) / 100) : 0));

          _row.subtotal_before_discount = this.parseToShortFloatFixed(_row.subtotal);
          _row.subtotal_after_discount = this.parseToShortFloatFixed(_row.subtotal);

          _row.insert_time = this.$moment().format('h:mm');
          this.selected_items.push(_row);

          let _index = this.selected_items.findIndex(item => item.id == row.id);
          // this.index_item_selected = _index;
          /**
           * get tax for item
           */
          this.getTaxValue(_index);

        }
      }

    },
    removeAllSelectedItem() {
      if (this.is_refund_available) {
        let _item = this.selected_items[this.index_item_selected];
        if (_item.is_return) {
          this.selected_items.splice(this.index_item_selected, 1);
        }
      } else {
        if (this.index_item_selected == null) {
          this.selected_items = [];
          this.shipping_val = 0;
        } else {
          this.selected_items.splice(this.index_item_selected, 1);
        }
      }

      this.index_item_selected = null;
      if (this.selected_items.length <= 0) {
        this.total_items = 0;
        this.total_final = 0;
        this.invoice_total = 0;
        this.total_dis = 0;
        this.total_tax = 0;
      }
    },
    calcItemsSelected: _.debounce(function (){

      let _selected_items = [];
      if (this.is_refund_available) {
        _selected_items = this.selected_items.filter(row => row.is_return);
      } else {
        _selected_items = this.selected_items.filter(row => !row.is_return);
      }


      if (this.is_refund_available) {

        this.total_items = this.$_.sumBy(_selected_items, function (row) {
          return ((row.sale_price ? parseFloat(row.sale_price) : 0) * (row.qty ? row.qty : 0))
        });
        this.total_items = this.parseToShortFloatFixed(this.total_items);


        // this.total_tax = this.$_.sumBy(_selected_items, function (row) {
        //   return (row.tax_val ? parseFloat(row.tax_val) : 0);
        // });
        // this.total_tax = this.total_tax.toFixed(2);


        this.total_dis = this.$_.sumBy(_selected_items, function (row) {
          let _sum = ((row.sale_price ? parseFloat(row.sale_price) : 0) * (row.qty ? row.qty : 0));
          if (row.dis_val) {
            return (row.dis_val ? parseFloat(row.dis_val) : 0)
          }
          if (row.dis_per) {
            return ((row.dis_per ? (parseFloat(row.dis_per) / 100) : 0) * (_sum ? _sum : 0))
          }
        });
        this.total_dis = this.total_dis ? this.parseToShortFloatFixed(this.total_dis) : 0;

        /**
         * sum amount before discount
         */
        this.total_amount_before_discount = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });

        /**
         * sum amount after discount
         */
        this.total_amount_after_discount = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });

        /**
         * summation tax dependent on  include_tax
         * if be 1 used to sum
         * another not used
         */
        // let _total_tax_included_tax = this.$_.sumBy(_selected_items, (row) => {
        //   let _arr_include_taxes = row.include_taxes;
        //   let _include_tax = null
        //   if (_arr_include_taxes && _arr_include_taxes[0]) {
        //     _include_tax = _arr_include_taxes[0];
        //   }
        //   if (_include_tax && _include_tax.include_tax == 1) {
        //     return row.tax_val ? parseFloat(row.tax_val) : 0;
        //   }
        //   return 0;
        // });
        let _total_tax = 0;
        if (this.is_apply_taxes_invoice_refund) {
           _total_tax = this.$_.sumBy(_selected_items, (row) => {
            return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
          });
          this.total_tax = this.$_.sumBy(_selected_items, (row) => {
            return row.amount_tax ? parseFloat(row.amount_tax) : 0;
          });
          this.total_tax = this.parseToShortFloatFixed(this.total_tax);
          this.total_out_tax = this.$_.sumBy(_selected_items, (row) => {
            if (!row.tax_applied || row.tax_applied.length == 0) {
              if (row.sale_price && row.qty) {
                return parseFloat(row.sale_price) * parseFloat(row.qty);
              }
            }
            return 0;
          });
        } else {
          this.total_tax = 0;
          this.total_out_tax = 0;
        }


        // let _total_items = this.$_.sumBy(_selected_items, function (row) {
        //
        //   let _sum = ((row.sale_price ? parseFloat(row.sale_price) : 0) * (row.qty ? row.qty : 0));
        //   if (row.dis_val) {
        //     _sum = _sum - (row.dis_val ? parseFloat(row.dis_val) : 0)
        //   } else if (row.dis_per) {
        //     _sum = _sum - ((row.dis_per ? (parseFloat(row.dis_per) / 100) : 0) * (_sum ? _sum : 0))
        //   }
        //   // if (row.include_tax && row.include_tax == 1) {
        //   //   _sum = _sum + (row.tax_val ? parseFloat(row.tax_val) : 0);
        //   // }
        //   return _sum;
        // });
        this.invoice_total_after_taxes = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_after_tax ? parseFloat(row.subtotal_after_tax) : 0);
        });
        this.invoice_total_before_taxes = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
        });

        if (this.invoice_total) {
          this.invoice_subtotal = this.parseToShortFloatFixed(((this.invoice_total ? parseFloat(this.invoice_total) : 0)  - (this.total_items ? parseFloat(this.total_items) : 0)) - _total_tax);
        }
        if (this.total_items) {
          this.total_final = this.parseToShortFloatFixed(((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis ? parseFloat(this.total_dis) : 0) + (this.shipping_val ? parseFloat(this.shipping_val) : 0) + _total_tax));
        }
      } else {

        this.total_items = this.$_.sumBy(_selected_items, function (row) {
          return ((row.sale_price ? parseFloat(row.sale_price) : 0) * (row.qty ? row.qty : 1))
        });

        this.total_items = this.parseToShortFloatFixed(this.total_items);

        this.total_tax = this.$_.sumBy(_selected_items, function (row) {
          return (row.amount_tax ? parseFloat(row.amount_tax) : 0);
        });
        this.total_tax = this.parseToShortFloatFixed(this.total_tax);

        this.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
          if (!row.tax_applied || row.tax_applied.length == 0) {
            if (row.sale_price && row.qty) {
              return parseFloat(row.sale_price) * parseFloat(row.qty);
            }
          }
          return 0;
        });


        this.total_dis = this.$_.sumBy(_selected_items, function (row) {
          let _sum = ((row.sale_price ? parseFloat(row.sale_price) : 0) * (row.qty ? row.qty : 1));
          if (row.dis_val) {
            return (row.dis_val ? parseFloat(row.dis_val) : 0)
          }
          if (row.dis_per) {
            return ((row.dis_per ? (parseFloat(row.dis_per) / 100) : 0) * (_sum ? _sum : 0))
          }
        });
        this.total_dis = this.total_dis ? this.parseToShortFloatFixed(this.total_dis) : 0;

        /**
         * sum amount before discount
         */
        this.total_amount_before_discount = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
        });

        /**
         * sum amount after discount
         */
        this.total_amount_after_discount = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
        });
        /**
         * summation tax dependent on  include_tax
         * if be 1 used to sum
         * another not used
         */
            // let _total_tax_included_tax = this.$_.sumBy(_selected_items, function (row) {
            //   if (row.include_tax && row.include_tax == 1) {
            //     return (row.tax_val ? parseFloat(row.tax_val) : 0);
            //   }
            //   return 0;
            // });

        let _amount_excluded_tax = this.$_.sumBy(_selected_items, (row) => {
              return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
            });
        this.setTotalTaxTypeVal();

        this.invoice_total_after_taxes = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_after_tax ? parseFloat(row.subtotal_after_tax) : 0);
        });
        this.invoice_total_before_taxes = this.$_.sumBy(_selected_items, (row) => {
          return (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0);
        });

        this.invoice_subtotal = 0;
        this.total_final = this.parseToShortFloatFixed(((this.total_items ? parseFloat(this.total_items) : 0) - (this.total_dis ? parseFloat(this.total_dis) : 0) + (this.shipping_val ? parseFloat(this.shipping_val) : 0) + (_amount_excluded_tax ? parseFloat(_amount_excluded_tax) : 0)));

      }
    },100) ,
    indexItemSelected(index) {
      if (this.index_item_selected == index) {
        this.index_item_selected = null;
      } else {
        this.index_item_selected = index;
      }
      this.num_calc = '';
      if (this.index_item_selected != null) {
        if (!this.selected_items[index].is_return && this.is_refund_available) {
          this.item_action = '';
          this.is_show_calc = false;
          this.num_calc = '';
        }
      }
      // this.item_action = null;
    },
    addAction(action, index = null) {
      this.item_action = action;
      this.is_show_calc = true;
      if (index != null){
        this.index_item_selected = index;
      }
      // // this.num_calc = '';
      // if (this.index_item_selected) {
      //   // if (!this.selected_items[this.index_item_selected].is_return && this.is_refund_available) {
      //     this.item_action = '';
      //     this.is_show_calc = false;
      //     this.num_calc = '';
      //   // }
      // }
    },
    home() {
      this.num_calc = '';
      this.is_show_calc = false;
      // this.categories = this.getCategories();
      this.items = this.getItems();
      this.isClient = false;
      this.item_action = null;
      this.data.name = '';
      this.data.type = 1;
      this.invoices = [];
      this.is_refund_available = false;
    },
    selectNumber(num) {
      if (num != 'enter' && num != 'confirm_with_update') {
        this.num_calc += num;
      } else {
        this.request_update_price_of_item = true;
        let promise = null;
        // let promise2 = null;
        if (num == 'confirm_with_update'){
          this.request_update_price_of_item = false;
          if (this.selected_items[this.index_item_selected] && this.selected_items[this.index_item_selected].id){
            let _id = this.selected_items[this.index_item_selected].id;
            promise = this.updatePriceOfItem(this.num_calc, _id);
          }

        }
        Promise.all([promise]).then(()=>{
          if (this.request_update_price_of_item){
            switch (this.item_action) {
              case 'add_qty':
                if (this.index_item_selected != null) {
                  if (parseFloat(this.selected_items[this.index_item_selected].available_stock_qty) >= parseFloat(this.num_calc) || row.is_committed == 1) {
                    this.selected_items[this.index_item_selected].qty = this.num_calc ? parseFloat(this.num_calc) : 0;
                    // this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
                    // this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
                  }
                }
                break;
              case 'dis_per':
                if (this.index_item_selected == null) {
                  this.inner_more_details.discount_type = 2;
                  this.inner_more_details.discount_value = this.num_calc;
                  this.setMoreDetails('discount');
                } else {
                  if (100 >= parseFloat(this.num_calc)) {
                    this.selected_items[this.index_item_selected].dis_per = this.num_calc;
                    this.selected_items[this.index_item_selected].dis_val = 0;
                    this.selected_items[this.index_item_selected].discount_type = 2;
                    this.selected_items[this.index_item_selected].discount_val = this.num_calc;

                    this.selected_items[this.index_item_selected].subtotal_before_discount =  this.selected_items[this.index_item_selected].subtotal;
                    this.selected_items[this.index_item_selected].subtotal_after_discount = this.selected_items[this.index_item_selected].subtotal - (this.selected_items[this.index_item_selected].subtotal * (parseFloat(this.selected_items[this.index_item_selected].discount_val) / 100));
                    this.selected_items[this.index_item_selected].subtotal_before_discount = this.parseToShortFloatFixed(this.selected_items[this.index_item_selected].subtotal_before_discount);
                    this.selected_items[this.index_item_selected].subtotal_after_discount = this.parseToShortFloatFixed(this.selected_items[this.index_item_selected].subtotal_after_discount);
                  }
                }

                break;
              case 'dis_val':
                if (this.index_item_selected == null) {
                  this.inner_more_details.discount_type = 3;
                  this.inner_more_details.discount_value = this.num_calc;
                  this.setMoreDetails('discount');
                } else {
                  if (parseFloat(this.selected_items[this.index_item_selected].subtotal) >= parseFloat(this.num_calc)) {
                    this.selected_items[this.index_item_selected].dis_val = this.num_calc;
                    this.selected_items[this.index_item_selected].dis_per = 0;
                    this.selected_items[this.index_item_selected].discount_type = 3;
                    this.selected_items[this.index_item_selected].discount_val = this.num_calc;

                    this.selected_items[this.index_item_selected].subtotal_before_discount =  this.selected_items[this.index_item_selected].subtotal;
                    this.selected_items[this.index_item_selected].subtotal_after_discount = this.selected_items[this.index_item_selected].subtotal - parseFloat(this.selected_items[this.index_item_selected].discount_val);
                    this.selected_items[this.index_item_selected].subtotal_before_discount = this.parseToShortFloatFixed(this.selected_items[this.index_item_selected].subtotal_before_discount);
                    this.selected_items[this.index_item_selected].subtotal_after_discount = this.parseToShortFloatFixed(this.selected_items[this.index_item_selected].subtotal_after_discount);
                  }
                }
                break;
              case 'price':
                if (this.index_item_selected != null) {
                  this.selected_items[this.index_item_selected].sale_price = this.num_calc;
                  this.selected_items[this.index_item_selected].unit_price = this.num_calc;
                  this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseFloat(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
                  // this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
                }
                break;
              case 'shipping':
                this.shipping_val = this.num_calc;
                break;
            }
            /**
             * get tax for item
             */
            // this.getTaxValue(this.index_item_selected);

            if (this.is_refund_available) {
              // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
              // this.getItemTaxForRefund(data, index)
              if (this.is_apply_taxes_invoice_refund) {
                this.getItemTaxCredit(this.index_item_selected);
              } else {
                this.getItemPriceRefund(this.index_item_selected)
              }

            }
            else {
              this.getTaxValue(this.index_item_selected);
            }

            this.calcItemsSelected();
            this.hideCalcModal();

          }
        })


      }

      // if (this.item_action == 'add_qty'){
      //     this.selected_items[this.index_item_selected].qty = this.num_calc;
      // }
      // if (this.item_action == 'dis_per'){
      //     this.selected_items[this.index_item_selected].dis_per = this.num_calc;
      //     this.selected_items[this.index_item_selected].dis_val = 0;
      // }
      // if (this.item_action == 'dis_val'){
      //     this.selected_items[this.index_item_selected].dis_val = this.num_calc;
      //     this.selected_items[this.index_item_selected].dis_per = 0;
      // }

      // this.calcItemsSelected();


    },
    restCalc() {
      this.num_calc = '';
      // if (this.index_item_selected != null) {
      //   switch (this.item_action) {
      //     case 'add_qty':
      //       this.selected_items[this.index_item_selected].qty = this.num_calc ? parseInt(this.num_calc) : 0;
      //       this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
      //       this.selected_items[this.index_item_selected].tax_val = (this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0)).toFixed(2);
      //       break;
      //     case 'dis_per':
      //       this.selected_items[this.index_item_selected].dis_per = this.num_calc;
      //       this.selected_items[this.index_item_selected].dis_val = 0;
      //       this.selected_items[this.index_item_selected].discount_type = 2;
      //       this.selected_items[this.index_item_selected].discount_val = this.num_calc;
      //       break;
      //     case 'dis_val':
      //       this.selected_items[this.index_item_selected].dis_val = this.num_calc;
      //       this.selected_items[this.index_item_selected].dis_per = 0;
      //       this.selected_items[this.index_item_selected].discount_type = 3;
      //       this.selected_items[this.index_item_selected].discount_val = this.num_calc;
      //       break;
      //     case 'price':
      //       this.selected_items[this.index_item_selected].sale_price = this.num_calc;
      //       this.selected_items[this.index_item_selected].subtotal = (this.selected_items[this.index_item_selected].qty ? parseInt(this.selected_items[this.index_item_selected].qty) : 0) * (this.selected_items[this.index_item_selected].sale_price ? parseFloat(this.selected_items[this.index_item_selected].sale_price) : 0);
      //       this.selected_items[this.index_item_selected].tax_val = this.selected_items[this.index_item_selected].subtotal * (this.selected_items[this.index_item_selected].total_taxes ? (parseFloat(this.selected_items[this.index_item_selected].total_taxes) / 100) : 0);
      //       break;
      //     case 'shipping':
      //       this.shipping_val = this.num_calc;
      //       break;
      //   }
      //   this.calcItemsSelected();
      // }
    },
    resetList() {
      this.selected_items = [];
      this.total_items = 0;
      this.total_final = 0;
      this.total_dis = 0;
      this.shipping_val = 0;
      this.invoice_total = 0;
      this.total_tax = 0;
      this.is_show_calc = false;
      this.isClient = false;
      this.is_refund_available = false;
      this.notes = null;
      this.getCategories();
      this.getItems();
    },
    //recalculation discount for general
    reCalcGeneralDiscount: _.debounce(function () {

      this.is_show_validation_dis_value = false;
      let _dis_type = this.inner_more_details.discount_type;
      let _all_dis_value = this.inner_more_details.discount_value ? parseFloat(this.inner_more_details.discount_value) : 0;
      let _invoice_total = this.total_final ? parseFloat(this.total_final) : 0;
      let _total_amount_before_discount = this.total_items ? parseFloat(this.total_items) : 0;
      let _dis_value = this.inner_more_details.discount_value ? parseFloat(this.inner_more_details.discount_value) : 0;
      if (_dis_value <= 0) {
        return false;
      }
      if (_all_dis_value > _invoice_total && _dis_type == 3) {
        this.is_show_validation_dis_value = true;
        return false;
      } else if (_dis_value > 100 && _dis_type == 2) {
        this.is_show_validation_dis_value = true;
        return false;
      }
      this.selected_items = this.selected_items.map((row, index) => {
        row.subtotal_before_discount = row.subtotal;
        row.discount_type = _dis_type;
        if (_dis_type == 1) {
          row.discount_val = 0;
          row.dis_per = 0;
          row.dis_val = 0;
        } else if (_dis_type == 3) {
          let _subtotal_before_discount = row.subtotal ? parseFloat(row.subtotal) : 0;
          let _dis = this.parseToShortFloatFixed((_subtotal_before_discount / _total_amount_before_discount) * parseFloat(_dis_value));
          row.discount_val = this.parseToShortFloatFixed(_dis);
          // row.discount_value = _dis_value ? parseFloat((parseFloat(_dis_value)).toFixed(2)) : 0;
          row.dis_val = row.discount_val;
          row.dis_per = 0;
          row.subtotal_after_discount = row.subtotal - parseFloat(row.discount_val);
        } else {
          row.dis_val = 0;
          row.dis_per = _dis_value;
          row.discount_val = _dis_value ? _dis_value : 0;
          row.subtotal_after_discount = row.subtotal - (row.subtotal * (parseFloat(_dis_value) / 100));
        }

        row.subtotal_before_discount = this.parseToShortFloatFixed(row.subtotal_before_discount);
        row.subtotal_after_discount = this.parseToShortFloatFixed(row.subtotal_after_discount);

        return row;
      });
      this.selected_items.forEach((row, index) => {
        if (this.is_refund_available) {
          // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
          // this.getItemTaxForRefund(data, index)
          if (this.is_apply_taxes_invoice_refund) {
            this.getItemTaxCredit(index);
          } else {
            this.getItemPriceRefund(index)
          }

        }
        else {
          this.getTaxValue(index);
        }
      });
      this.calcItemsSelected();
    }, 100),
    setMoreDetails(type) {
      if (type == 'discount') {
        this.reCalcGeneralDiscount();
      }
    },
    showProductInfo() {
      this.show_product_info = !this.show_product_info;
    },
    selectToShowProductInfo(id) {
      this.productId = id;
      this.showProductInformationModal();
    },
    cloneSelectedItem(index) {
      if (this.is_refund_available) {
        this.index_item_selected = index;
        let _id = this.selected_items[this.index_item_selected].id;
        let _remaining_qty = this.selected_items[this.index_item_selected].remaining_qty;
        let _filter = this.selected_items.filter(row => (row.id == _id && row.is_return));
        if (_filter.length == 0) {
          let _clone = {...this.selected_items[this.index_item_selected]};
          _clone.remaining_qty = _remaining_qty;
          _clone.is_return = true;
          // this.selected_items.splice(this.index_item_selected + 1, 0, _clone);
          // this.selected_items[this.index_item_selected].qty = 0;
          let _sale_price = (_clone.subtotal_after_discount ? (parseFloat(_clone.subtotal_after_discount) / _clone.qty) : 0);
          _clone.sale_price = _sale_price;
          _clone.qty = 1;
          _clone.subtotal = _clone.qty * _sale_price;
          _clone.subtotal_before_tax = _clone.qty * _sale_price;

          _clone.discount_type = 1;
          _clone.discount_val = 0;
          _clone.discount_value = 0;
          _clone.dis_val = 0;
          _clone.dis_per = 0;

          this.selected_items.push(_clone);
          this.index_item_selected = null;

          /**
           * calc tax in return
           * @type {{item_id, qty}}
           */
          // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
          // this.getItemTaxForRefund(data, index);
        }
      }
    },
    makeRefundAvailable(id, refund_all = false) {
      this.is_refund_available = true;
      this.invoice_url = '/print/point-sale-invoice-refund';
      this.$emit('set-type-invoice', true);
      this.getItemsInvoice(id, refund_all);
    },
    getStepSpinButton(num = 0) {
      // return num;
      if (num == 0) {
        return 1;
      } else {
        let _num = this.parseToShortFloatFixed(num);
        // num.toFixed(4);
        let array_num = _num.split('.');
        if (array_num[1]) {
          if (array_num[1] == 0) {
            return 1;
          } else {
            return '0.' + array_num[1];
          }
        } else {
          return 1;
        }

      }
    },
    refactorCart(){
      let _selected_items = [...this.selected_items];
      this.selected_items = [];
      setTimeout(()=>{
        this.selected_items = [..._selected_items];
      }, 1)


    },
    changeQtyModal(){
      this.changeQty(this.index_cart_qty, this.cart_qty);
      this.hideChangeQtyModal();
    },
    // changeQtyNormalInput(index, event) {
    //   let _event = event.target.value ? event.target.value: 1;
    //   this.changeQty(data.index, _event)
    // },
    changeQtyNormalInput(data) {
      let _event = data.value ? data.value: 1;
      // this.selected_items[data.index].qty = _event;
      // setTimeout(()=>{
      //   this.refactorCart();
        this.changeQty(data.index, _event)
      // }, 100)


    },
    changeQty(index, event) {
      // console.log(this.selected_items[index],index, event);
      // if(parseInt(this.selected_items[index].available_stock_qty) >= parseInt(event)){

      if (!this.selected_items[index]) {
        return;
      }

      if (this.selected_items[index].is_return) {
        let _qty = (event ? parseFloat(event) : 0);
        let _remaining_qty = (this.selected_items[index].remaining_qty ? parseFloat(this.selected_items[index].remaining_qty) : 0);
        if (_qty > _remaining_qty) {
          this.selected_items[index].qty = _remaining_qty;
        } else {
          this.selected_items[index].qty = (event ? parseFloat(event) : 0);
        }
        // let _original_index =  this.selected_items.findIndex((row)=> (row.item_id == this.selected_items[index].item_id && !row.is_return));
        // this.selected_items[_original_index].qty = (this.selected_items[index].original_qty ? parseInt(this.selected_items[index].original_qty) : 0) - (this.selected_items[index].qty ? parseInt(this.selected_items[index].qty) : 0);
      } else {
        if (parseFloat(event) >= parseFloat(this.selected_items[index].available_stock_qty) && this.selected_items[index].is_committed == 0) {
          this.selected_items[index].qty = parseFloat(this.selected_items[index].available_stock_qty);
        }else {
          this.selected_items[index].qty = (event ? parseFloat(event) : 0);
        }

      }
      this.selected_items[index].subtotal = this.parseToShortFloatFixed((this.selected_items[index].qty ? parseFloat(this.selected_items[index].qty) : 0) * (this.selected_items[index].sale_price ? parseFloat(this.selected_items[index].sale_price) : 0));

      /**
       * get tax for item
       */
      // this.$._.debounce();
      setTimeout(()=>{
        if (this.is_refund_available) {
          // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
          // this.getItemTaxForRefund(data, index)
          if (this.is_apply_taxes_invoice_refund) {
            this.getItemTaxCredit(index);
          } else {
            this.getItemPriceRefund(index)
          }
        }
        else {
            this.getTaxValue(index);
        }
      },200);

      this.selected_items[index].subtotal_before_discount = this.parseToShortFloatFixed(parseFloat(this.selected_items[index].subtotal));
      this.selected_items[index].subtotal_after_discount = this.parseToShortFloatFixed(parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val));

      // this.selected_items[index].tax_val = (this.selected_items[index].subtotal * (this.selected_items[index].total_taxes ? (parseFloat(this.selected_items[index].total_taxes) / 100) : 0)).toFixed(2);

      this.calcItemsSelected();


      // }
    },
    getQtyFromItemCart(item_p) {
      let _ids = [];
      _ids = this.selected_items.map((row) => {
        return row.id;
      });
      if (_ids.includes(item_p.id)) {
        let _index = this.selected_items.findIndex(item => item.id == item_p.id);
        return this.selected_items[_index] ? this.selected_items[_index].qty : 0;
      } else {
        return 0;
      }
    },
    removeItem(index) {
      // if (this.selected_items[index].is_return){
      //   let _original_index =  this.selected_items.findIndex((row)=> (row.item_id == this.selected_items[index].item_id && !row.is_return));
      //   this.selected_items[_original_index].qty = this.selected_items[index].original_qty;
      // }
      this.selected_items.splice(index, 1);
      this.calcItemsSelected();
    },
    setDiscountType(index, type) {
      // this.selected_items[index].discount_type = type;
      // if (type == 2) {
      //   this.selected_items[index].dis_per = this.selected_items[index].discount_val;
      //   this.selected_items[index].dis_val = 0;
      // } else if (type == 3) {
      //   this.selected_items[index].dis_val = this.selected_items[index].discount_val;
      //   this.selected_items[index].dis_per = 0;
      // }
      //
      // this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
      // this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val);
      //
      // this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal_before_discount).toFixed(2);
      // this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal_after_discount).toFixed(2);
      this.calcDiscountForType(index, type);
      if (this.is_refund_available) {
        // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
        // this.getItemTaxForRefund(data, index)
        if (this.is_apply_taxes_invoice_refund) {
          this.getItemTaxCredit(index);
        } else {
          this.getItemPriceRefund(index)
        }

      }
      else {
        this.getTaxValue(index);
      }
      // this.calcItemsSelected();
    },
    calcDiscountForType(index, type){
      this.selected_items[index].discount_type = type;
      let _ev = this.selected_items[index].discount_val;
      if (type == 2) {
        this.selected_items[index].dis_per = (_ev > 100 ? 100 : _ev);
        this.selected_items[index].discount_val = (_ev > 100 ? 100 : _ev);
        this.selected_items[index].dis_val = 0;
        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - (parseFloat(this.selected_items[index].subtotal) * (parseFloat(this.selected_items[index].discount_val)/100));
      } else if (type == 3) {
        this.selected_items[index].dis_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
        this.selected_items[index].discount_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
        this.selected_items[index].dis_per = 0;

        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val);
      }else {
        this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
        this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal);
      }

      this.selected_items[index].subtotal_before_discount = this.parseToShortFloatFixed(this.selected_items[index].subtotal_before_discount);
      this.selected_items[index].subtotal_after_discount = this.parseToShortFloatFixed(this.selected_items[index].subtotal_after_discount);
    },
    setDiscountValPer(event, index) {
      // if (event.target.value) {
        let _ev = event.target.value ? parseFloat(event.target.value) : 0;

        if (this.selected_items[index].discount_type == 2) {
          this.selected_items[index].dis_per = (_ev > 100 ? 100 : _ev);
          this.selected_items[index].discount_val = (_ev > 100 ? 100 : _ev);
          this.selected_items[index].dis_val = 0;
          this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
          this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - (parseFloat(this.selected_items[index].subtotal) * (parseFloat(this.selected_items[index].discount_val)/100));

        } else if (this.selected_items[index].discount_type == 3) {
          this.selected_items[index].dis_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
          this.selected_items[index].discount_val = (_ev > this.selected_items[index].subtotal ? this.selected_items[index].subtotal : _ev);
          this.selected_items[index].dis_per = 0;

          this.selected_items[index].subtotal_before_discount = parseFloat(this.selected_items[index].subtotal);
          this.selected_items[index].subtotal_after_discount = parseFloat(this.selected_items[index].subtotal) - parseFloat(this.selected_items[index].discount_val);

        }
        this.selected_items[index].subtotal_before_discount = this.parseToShortFloatFixed(this.selected_items[index].subtotal_before_discount);
        this.selected_items[index].subtotal_after_discount = this.parseToShortFloatFixed(this.selected_items[index].subtotal_after_discount);



        setTimeout(() => {
          if (this.is_refund_available) {
            // let data = {qty: this.selected_items[index].qty, item_id: this.selected_items[index].item_id};
            // this.getItemTaxForRefund(data, index)
            if (this.is_apply_taxes_invoice_refund) {
              this.getItemTaxCredit(index);
            } else {
              this.getItemPriceRefund(index)
            }

          }
          else {
            this.getTaxValue(index);
          }
        }, 100)


      // }

      // this.calcItemsSelected();
    },
    // getAvailableQuantityForItem(id, qty) {
    //     return ApiService.get(`${this.mainRouteDependency}/get-available-quantity-for-item`, {params:{
    //             id: id,
    //             qty: qty
    //         }}).then((response) => {
    //             console.log(response);
    //             return true;
    //     }).catch((error)=>{
    //         this.$errorAlert(error);
    //         return false;
    //     });
    // },
    /**
     * get tax for normal invoice
     * item_id
     * item_total
     * discount_type
     * discount_value
     * this endpoint dependent on all them to calc tax
     * @param index
     */
    // getTaxValue(index) {
    //   if (this.selected_items && this.selected_items[index]) {
    //     let item_id = this.selected_items[index].id;
    //     let item_total = this.selected_items[index].subtotal;
    //     let discount_type = this.selected_items[index].discount_type;
    //     let discount_value = this.selected_items[index].discount_val;
    //     ApiService.query(`${this.mainRouteDependency}/tax_discount_calculation`, {
    //       item_id: item_id,
    //       item_total: item_total,
    //       discount_type: discount_type,
    //       discount_value: discount_value,
    //       tax_rule_id: 1,
    //     }).then((response) => {
    //       this.selected_items[index].tax_val = response.data.data.tax_value;
    //       this.selected_items[index].include_tax = response.data.data.include_tax;
    //       this.calcItemsSelected();
    //     });
    //   }
    //
    // },
    async updatePriceOfItem(price, id){
      if (price && id)
      await ApiService.put(`/items/items/${id}/sale_price`, {
        sale_price: price,
          }).then((response) => {
        this.$successAlert(response.data.message);
        this.request_update_price_of_item = true;
        Vue.prototype.$postStatus = true;
        this.searchItems(false);
      }).catch((error) => {
        this.$errorAlert(error);
        this.request_update_price_of_item = false;
        Vue.prototype.$postStatus = true;
      });
    },
    async getTaxValue(index) {
      if(!this.selected_items[index]){
        return;
      }
      if (this.isApplyTaxesInvoice) {
        let item_id = this.selected_items[index].id ? this.selected_items[index].id : null;
        let quantity = this.selected_items[index].qty ? this.selected_items[index].qty : null;
        let unit_price = this.selected_items[index].sale_price ? this.selected_items[index].sale_price : null;
        let discount = this.selected_items[index].discount_val ? this.selected_items[index].discount_val : null;
        let discount_type = this.selected_items[index].discount_type ? this.selected_items[index].discount_type : null;

        if (item_id && quantity && unit_price && this.isApplyTaxesInvoice)
          await ApiService.get(`/v2/items/calculate-subtotal`,
              {
                params: {
                  item_id: item_id, quantity: quantity, sale_price: unit_price,
                  discount: discount, discount_type: discount_type, row_index: index
                }
              }).then((response) => {
            this.selected_items[response.data.data.row_index].amount_tax = response.data.data.tax_total ? this.parseToShortFloatFixed(response.data.data.tax_total) : 0;
            this.selected_items[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
            this.selected_items[response.data.data.row_index].amount_excluded_tax = response.data.data.excluded_taxes_total ? this.parseToShortFloat(response.data.data.excluded_taxes_total) : 0;
            this.selected_items[response.data.data.row_index].amount_included_tax = response.data.data.included_taxes_total ? this.parseToShortFloat(response.data.data.included_taxes_total) : 0;


            this.selected_items[index].total_item_after_discount_with_excluded = this.parseToShortFloatFixed(parseFloat(this.selected_items[response.data.data.row_index].subtotal_after_discount) + parseFloat(this.selected_items[response.data.data.row_index].amount_excluded_tax));

            let _subtotal = (this.selected_items[response.data.data.row_index].sale_price ? this.selected_items[response.data.data.row_index].sale_price : 0) * (this.selected_items[response.data.data.row_index].qty ? this.selected_items[response.data.data.row_index].qty : 0);
            this.selected_items[response.data.data.row_index].subtotal_before_tax = this.parseToShortFloatFixed(_subtotal - (this.selected_items[response.data.data.row_index].amount_included_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_included_tax) : 0));
            this.selected_items[response.data.data.row_index].subtotal_after_tax = this.parseToShortFloatFixed(_subtotal + (this.selected_items[response.data.data.row_index].amount_excluded_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_excluded_tax) : 0));

            this.calcDiscountForType(response.data.data.row_index, this.selected_items[response.data.data.row_index].discount_type);

            setTimeout(()=>{
              this.calcItemsSelected();

            }, 100);

          });
      }else{
        /**
         * in this script make a calc with all taxes values 0
         *
         */
        this.selected_items[index].total_item_after_discount_with_excluded = this.parseToShortFloatFixed(parseFloat(this.selected_items[index].subtotal_after_discount) + parseFloat((this.selected_items[index].amount_excluded_tax ? this.selected_items[index].amount_excluded_tax : 0)));
        let _subtotal = (this.selected_items[index].sale_price ? this.selected_items[index].sale_price : 0) * (this.selected_items[index].qty ? this.selected_items[index].qty : 0);
        // this.selected_items[index].subtotal_before_tax = (_subtotal - (this.selected_items[index].amount_included_tax ? this.selected_items[index].amount_included_tax : 0)).toFixed(2);
        // this.selected_items[index].subtotal_after_tax = (_subtotal + (this.selected_items[index].amount_excluded_tax? this.selected_items[index].amount_excluded_tax : 0)).toFixed(2);
        this.selected_items[index].subtotal_before_tax = this.parseToShortFloatFixed(_subtotal);
        this.selected_items[index].subtotal_after_tax = this.parseToShortFloatFixed(_subtotal);

        this.calcDiscountForType(index, this.selected_items[index].discount_type);

        setTimeout(()=>{
          this.calcItemsSelected();

        }, 100);
      }

    },

    async getItemPriceRefund(index) {
      let item_id = this.selected_items[index].item_id ? this.selected_items[index].item_id : null;
      let quantity = this.selected_items[index].qty ? this.selected_items[index].qty : null;
      let discount = this.selected_items[index].discount_val ? this.selected_items[index].discount_val : null;
      let discount_type = this.selected_items[index].discount_type ? this.selected_items[index].discount_type : null;

      if (item_id && quantity)
        await ApiService.get(`/v2/items/sale/${this.invoice_id}/refund/calculate-subtotal`,
            {
              params: {
                item_id: item_id, quantity: quantity,
                discount: discount, discount_type: discount_type, row_index: index
              }
            }).then((response) => {

          this.selected_items[response.data.data.row_index].sale_price = response.data.data.unit_price ? this.parseToShortFloatFixed(response.data.data.unit_price) : 0;

          let _subtotal = (this.selected_items[index].sale_price ? this.selected_items[index].sale_price : 0) * (this.selected_items[index].qty ? this.selected_items[index].qty : 0);
          this.selected_items[index].subtotal_before_tax = this.parseToShortFloatFixed(_subtotal);
          this.selected_items[index].subtotal_after_tax = this.parseToShortFloatFixed(_subtotal);
            this.calcItemsSelected();

        });
      // }
    },

    async getItemTaxCredit(index) {
      let item_id = this.selected_items[index].item_id ? this.selected_items[index].item_id : null;
      let quantity = this.selected_items[index].qty ? this.selected_items[index].qty : null;
      let discount = this.selected_items[index].discount_val ? this.selected_items[index].discount_val : null;
      let discount_type = this.selected_items[index].discount_type ? this.selected_items[index].discount_type : null;

      if (item_id && quantity)
        await ApiService.get(`/v2/items/sale/${this.invoice_id}/credit-note/calculate-subtotal`,
            {
              params: {
                item_id: item_id, quantity: quantity,
                discount: discount, discount_type: discount_type, row_index: index
              }
            }).then((response) => {
          this.selected_items[response.data.data.row_index].amount_tax = this.parseToShortFloatFixed(response.data.data.tax_total);
          this.selected_items[response.data.data.row_index].amount_excluded_tax = this.parseToShortFloatFixed(response.data.data.excluded_tax_per_item);
          this.selected_items[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
          this.selected_items[response.data.data.row_index].sale_price = response.data.data.unit_price ? this.parseToShortFloatFixed(response.data.data.unit_price) : 0;

          let _subtotal = (this.selected_items[index].sale_price ? this.selected_items[index].sale_price : 0) * (this.selected_items[index].qty ? this.selected_items[index].qty : 0);
          this.selected_items[response.data.data.row_index].subtotal_before_tax = this.parseToShortFloatFixed(_subtotal - (this.selected_items[response.data.data.row_index].amount_included_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_included_tax) : 0));
          this.selected_items[response.data.data.row_index].subtotal_after_tax = this.parseToShortFloatFixed(_subtotal + (this.selected_items[response.data.data.row_index].amount_excluded_tax ? parseFloat(this.selected_items[response.data.data.row_index].amount_excluded_tax) : 0));

          this.calcItemsSelected();


        });
    },
    /**
     * this is function used to collection and summation every tax
     * as an object
     */
    setTotalTaxTypeVal() {
      this.total_tax_type_val = [];
      this.selected_items.forEach((row) => {
        if (row.tax_applied && row.tax_applied.length > 0) {
          row.tax_applied.forEach((_row) => {
            let index_tax = this.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0) {
              this.total_tax_type_val[index_tax].tax_value = this.parseToShortFloatFixed(parseFloat(this.total_tax_type_val[index_tax].tax_value) + parseFloat(_row.tax_value));
              this.total_tax_type_val[index_tax].amount_applied = this.parseToShortFloatFixed(parseFloat(this.total_tax_type_val[index_tax].amount_applied) + parseFloat(row.subtotal_before_tax));
            } else {
              this.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: _row.tax_value, amount_applied: row.subtotal_before_tax});
            }
          });

        }
        row.tax_applied
      });
    },

    /**
     * this function used
     * invoice_id and item_id and quantity
     */
    // async getItemTaxForRefund(data, index) {
    //   await ApiService.query(this.mainRouteDependency + "/tax_discount_calculation_refund/" + this.invoice_id, {
    //     ...data
    //   }).then((response) => {
    //     if (response.data.data.price_per_unit) {
    //       this.selected_items[index].sale_price = response.data.data.price_per_unit;
    //     }
    //     this.selected_items[index].tax_val = response.data.data.tax_value;
    //     this.selected_items[index].discount = response.data.data.discount;
    //     this.selected_items[index].include_taxes = response.data.data.include_taxes;
    //   });
    // },

    showRefundFullyInvoiceModal(invoice_id, refund_amount) {
      this.$refs['refundFullyInvoiceModal'].show();
      this.invoice_id = invoice_id;
      this.refund_amount = refund_amount;
    },
    hideRefundFullyInvoiceModal() {
      this.$refs['refundFullyInvoiceModal'].hide();
      this.invoice_id = null;
      this.refund_amount = null;
    },
    confirmedRefund() {
      ApiService.post(this.mainRefundFullyInvoiceRoute, {
        invoice_id: this.invoice_id,
        payment_amount: this.refund_amount,
        payment_method: this.payment_method,
        is_refund_all: 1
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.hideRefundFullyInvoiceModal();
        window.open('/print/point-sale-invoice-refund', "_blank");
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
        Vue.prototype.$postStatus = true;
      });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        // this.currency = response.data.data.currency_name;
        // this.currency_id = response.data.data.currency_id;
        if (this.user) {
          this.data.user_id = this.user.id;
          // this.isDisabled = false;
        }
      });
    },

    // async getDefaultPaymentMethod() {
    //   await ApiService.get(`${this.mainRouteDependency}/pos_default_payment_method`).then((response) => {
    //     this.is_cash = response.data.data.id;
    //     this.payment_method = response.data.data.id;
    //     this.method1_name = response.data.data.title;
    //   });
    // },

    async getItemByBarcodeScanner(row) {
      if (row)
        await ApiService.get(`${this.mainRouteDependency}/item_info_by_barcode?barcode=${row}`).then((response) => {
          // ApiService.get(`${this.mainRouteDependency}/item_info_by_barcode?barcode=9910001013507`).then((response) => {

          if (response.data.data.info) {
            let _item = response.data.data.item;
            _item.item_sales_barcode = row;
            this.selectItem(_item, response.data.data.info);
          } else {
            let _item = response.data.data;
            _item.item_sales_barcode = row;
            this.selectItem(_item);
          }
        });
    },

    printShowingPayedInvoiceScreen() {
      this.$emit('print-showing-payed-invoice');
    },

    // // ---------------------------- 1 ----------------------------
    // setupBarcodeScanner(e) {
    //   if (!this.hasOwnProperty('scan')) {
    //     this.scan = [];
    //   }
    //
    //   document.addEventListener('keydown', this.handleBarcodeKeydown);
    //   document.addEventListener('scanComplete', this.handleScanComplete);
    //   console.log(e);
    //   this.searchOnEnterInput(e);
    //   this.data.barcode = '';
    // },

    // handleBarcodeKeydown(e) {
    //   console.log(e);
    //   this.data.barcode = '';
    //   if (e.key !== 'Enter') {
    //     let code = e.code.replaceAll(e.key, '');
    //     if (code == 'Digit'){
    //       this.scan.push(e.key);
    //     }
    //   }else {
    //     if (this.scan.length > 0){
    //       this.scan.forEach((val)=>{
    //         this.data.barcode += val;
    //       });
    //     }
    //     this.scan = [];
    //     this.getItemByBarcodeScanner(this.data.barcode)
    //   }

    // if (this.scan.length > 0 && (e.timeStamp - this.scan.slice(-1)[0].timeStamp) > 10) {
    //   this.scan = [];
    // }
    //
    // if (e.key === 'Enter' && this.scan.length > 0) {
    //   let scannedString = this.scan.reduce((scannedString, entry) => scannedString + entry.key, '');
    //   this.scan = [];
    //   document.dispatchEvent(new CustomEvent('scanComplete', { detail: scannedString }));
    // }
    //
    // if (e.key !== 'Shift') {
    //   let data = JSON.parse(JSON.stringify(e, ['key', 'timeStamp']));
    //   data.timeStampDiff = this.scan.length > 0 ? data.timeStamp - this.scan.slice(-1)[0].timeStamp : 0;
    //
    //   this.scan.push(data);
    // }
    // this.searchOnEnterInput(e);
    // this.data.barcode = '';
    //
    // console.log(this.scan);
    // },

    // handleScanComplete(e) {
    //   console.log(e);
    //   console.log(e.detail);
    // },

    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      this.index_item_selected = null;
      this.data.barcode = barcode;
      let promise = this.getItemByBarcodeScanner(barcode);
      Promise.all([promise]).then(() => {
        this.data.barcode = '';
      })
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      let barcode = this.$barcodeScanner.getPreviousCode();
      this.data.barcode = '';
      // do something...
    },

    initialOnClickKeyboard() {
      /**
       * this listener for read event changed from keyboard
       *
       */
      let that = this;
      let _num = '';
      let _num_time = 0;
      document.addEventListener('keyup', (ev) => {

        let _arr_ele_class = [];
        if (document.activeElement && document.activeElement.className) {
          _arr_ele_class = document.activeElement.className.split(" ");
          if (_arr_ele_class.includes('form-control'))
            return true;
        }

        if ((that.index_item_selected != null && !isNaN(ev.key)) || (that.index_item_selected != null && ev.key == '.')) {
          _num_time = _num_time + 1;
          _num += ev.key;
          setTimeout(() => {
             that.changeQty(that.index_item_selected, _num);
            _num_time = 0;
          }, 1500)
          setTimeout(() => {
            _num = '';
          }, 2500);

          ev.preventDefault();
          return false;
        }
        // else if (ev.key == 'ArrowUp' && that.index_item_selected > 0){
        //   that.index_item_selected -= 1;
        //   ev.preventDefault();
        //   return false;
        // }else if (ev.key == 'ArrowDown' && that.index_item_selected < that.selected_items.length){
        //   this.index_item_selected += 1;
        //   ev.preventDefault();
        //   return false;
        // }

      }, false);

      // document.addEventListener('keydown', function(e) {
      //
      //   // To make sure it freezes the scroll when
      //   // the first two keypresses are "ArrowUp"
      //   if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      //     e.preventDefault();
      //     return false;
      //   }
      // })
    },
    disabledOnClickKeyboard() {
      this.index_item_selected = null;
    },
    isUsedToBeActive(row) {
      if (row.sale_price == 0) {
        return false;
      } else if (row.is_committed == 0 && row.available_stock_qty == 0) {
        return false;
      } else {
        return true;
      }
    },

    // getTaxSettings() {
    //   ApiService.get(this.mainTaxSettingsRoute).then((response) => {
    //     this.is_apply_taxes_invoice = response.data.data.is_apply_taxes_setting;
    //   });
    // },

    initPropsData() {
      this.is_specail_category = this.propsData.is_specail_category;
      this.default_print_invoice_size = this.propsData.default_print_invoice_size;
      this.default_print_refund_size = this.propsData.default_print_refund_size;
      if (this.propsData.default_payment_method) {
        this.is_cash = this.propsData.default_payment_method.id;
        this.payment_method = this.propsData.default_payment_method.id;
        this.method1_name = this.propsData.default_payment_method.title;
      }
      if (this.propsData.pos_default_customer) {
        this.switchOnProducts(this.propsData.pos_default_customer.name, this.propsData.pos_default_customer)
      }
      if (this.propsData.is_apply_taxes_setting) {
        this.is_apply_taxes_invoice = this.propsData.is_apply_taxes_setting;
      }
      if (this.propsData.payment_methods_list) {
        this.paymentMethodsList = this.propsData.payment_methods_list;
      }
      if (this.propsData.request_invoice) {
        this.request_invoice = this.propsData.request_invoice;
      }

    },
    initCurrency(){
      this.currency_id = this.currencyId;
      this.currency_code = this.currencyCode;
      this.currency = this.currencyCode;
    },
    initForTotals(){
      this.total_items = 0;
      this.total_final = 0;
      this.total_dis = 0;
      this.shipping_val = 0;
      this.invoice_total = 0;
      this.total_tax = 0;
    },
  },
  created() {
    this.initPropsData()
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.point_of_sales")}]);
    // this.getAuthUser();
    this.initCurrency();
    this.session_id = this.sessionId;

    // this.currency = this.innerCurrency;

    this.user = this.innerUser;
    if (this.innerUser) {
      this.data.user_id = this.innerUser.id;
    }
    // this.getTaxSettings();
    this.getCategories();
    this.getItems();
    // this.getCustomers();
    // this.getDefaultCustomer();
    // this.getDefaultPaymentMethod();
    this.initialOnClickKeyboard();


    this.$barcodeScanner.init(this.onBarcodeScanned);
    // this.setupBarcodeScanner();
    // this.invoice_url = this.is_refund_available == false ? '/print/point-sale-invoice' : '/print/point-sale-invoice-refund';

    this.date_now = this.$moment().format('DD-MM-YYYY, h:mm:ss a');

    // this.$nextTick(() => {
    //   console.log(this.$refs["barcodeInput"+this.indexScreen]);
    //  if (this.$refs["barcodeInput"+this.indexScreen]){
    //    this.$refs["barcodeInput"+this.indexScreen].focus()
    //  }
    //
    // })
  },
}
</script>