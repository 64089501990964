<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.branch') }}<span class="text-danger">*</span></label>
        <input type="text" class="form-control" :class="  validation && validation.branch_name ? 'is-invalid' : ''" v-model="data.branch_name"/>
        <span v-if="validation && validation.branch_name" class="fv-plugins-message-container invalid-feedback">
                {{ validation.branch_name[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.name_on_invoice') }}<span class="text-danger">*</span></label>
        <input type="text" class="form-control" :class="  validation && validation.invoice_name ? 'is-invalid' : ''" v-model="data.invoice_name"/>
        <span v-if="validation && validation.invoice_name" class="fv-plugins-message-container invalid-feedback">
                {{ validation.invoice_name[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.country') }}</label>
        <div class="input-group mb-3">
          <multiselect v-model="country"
                       :placeholder="$t('customers.country')"
                       label="name"
                       track-by="id"
                       :options="countries"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @search-change="getCountries($event)"
                       :internal-search="false">
          </multiselect>
        </div>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('customers.country') }}.</span>
        <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                {{ validation.country_code[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.city') }}</label>
        <div class="input-group mb-3">
          <multiselect v-model="city"
                       :placeholder="$t('customers.city')"
                       label="name" track-by="id"
                       :options="cities"
                       :multiple="false"
                       :taggable="true"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                {{ validation.city_id[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.mobile') }}</label>
        <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
        <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                {{ validation.mobile[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.address_1') }}</label>
        <input type="text" class="form-control" :class="  validation && validation.address1 ? 'is-invalid' : ''" v-model="data.address1"/>
        <span v-if="validation && validation.address1" class="fv-plugins-message-container invalid-feedback">
                {{ validation.address1[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.is_active') }}</label>
        <div class="input-group">
          <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
  name: "customer-branch-form",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    customerId: null,
  },
  data() {
    return {
      mainRoute: 'customers/customer_branches',
      mainRouteDependency: 'base/dependency',
      data: {

        customer_id: null,
        city_id: null,
        country_code: null,
        mobile: '',
        address1: null,
        branch_name: null,
        invoice_name: null,
        is_active: true
      },
      data_list: [],
      countries: [],
      cities: [],
      validation: null,
      country: [],
      city: [],
      default_data: {
        country_code: null,
        city_id: null,
        country: null,
        city: null,
      },

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },

  watch: {

    country: function (val) {
      if (val && val.code2) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
        this.cities = [];
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
    },
    resetAfterSave() {
      this.data = {
        customer_id: this.customerId,
        city_id: null,
        country_code: null,
        branch_name: null,
        invoice_name: null,
        mobile: '',
        address1: null,
        is_active: true,
        id: null,
      };
      
      this.country = [];
      this.city = [];
      this.$emit('handling-data');

    },
    mobileRepeaterChanged(obj) {
      if (obj) {
        this.data.mobile = obj.dialCode;
      } else {
        this.data.mobile = '';
      }
    },

    save() {
      this.create();
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.cancel();
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      } else {
        this.countries = [];
      }
    },
    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },


    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {

        this.data.country_code = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.country = response.data.data.country;
        this.city = response.data.data.city;


        this.default_data.country_code = response.data.data.country_id;
        this.default_data.city_id = response.data.data.city_id;
        this.default_data.country = response.data.data.country;
        this.default_data.city = response.data.data.city;

      });
    },
  },

  mounted() {
    this.defaultDataForUser();
    this.data.customer_id = this.customerId;
  },
}
</script>

<style scoped>

</style>